import React from 'react'
import classes from './Users.module.css'
import { PrimaryButton } from '../common/buttons/buttons'
import {pageDefaultHeight} from './../common/sizes'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import UsersTable from './UsersTable'
import UsersModal from './UsersModal'
import Select from '../common/Select/Select'

const Users = (props) => {
  return (
    <>
      <header className={classes.header}>
        <Search onChange={event => props.search(event.target.value)} />
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <div className={classes.titleArea} >
          <h1 className={classes.pageTitle}>
            USERS
          </h1>
          <div>
            <Select
              options={props.userTypeOptions}
              onSelect={(value) => props.selectUserType(value)}
              style={{width: '200px', marginRight: '15px'}}
              value={props.selectedUserType}
              className='smallSelect'
            />
            <PrimaryButton 
              text='ADD USER' 
              onClick={() => props.setModalData({isOpen: true})}
            />
          </div>
        </div>
        <UsersTable 
          removeUser={props.removeUser}
          setModalData={props.setModalData}
          tableRows={props.tableRows}
        />
        {props.modalData.isOpen && 
          <UsersModal
            modalData={props.modalData}
            setModalData={props.setModalData}
          />
        }
      </div>
    </>
  )
}

export default Users

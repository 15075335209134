import React from 'react'
import {Divider, Dropdown, Menu, Popover} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import _ from 'lodash'
import classes from './UserInfoArea.module.css'
import defaultAvatar from './../../../img/userAvatar.png'
import {ReactComponent as ExportIcon} from './../../../img/icons/export.svg'
import {ReactComponent as NotificationBadged} from './../../../img/icons/notificationBadged.svg'
import {ReactComponent as Notification} from './../../../img/icons/notification.svg'
import {useDispatch, useSelector} from 'react-redux'
import {logOut} from './../../../store/authReducer'
import {getNewNotifications, markNotificationAsSeen, removeNotification} from './../../../store/notificationsReducer'
import {ReactComponent as NewNotificationIcon} from './../../../img/icons/newNotificationIcon.svg'
import {ReactComponent as CloseIcon} from './../../../img/icons/closeIcon.svg'
import moment from 'moment'

const UserInfoArea = (props) => {
  const dispatch = useDispatch()
  // userName = `${firstName} ${lastName}`
  const userName = useSelector(state => state.authReducer.userName)
  const newNotifications = useSelector(store => store.notificationsReducer.newNotifications)
  const userAvatar = useSelector(state => state.authReducer.avatar)

  const seeNewNotifications = () => {
    newNotifications
      .filter(n => !n.is_seen)
      .forEach(n => dispatch(markNotificationAsSeen(n))) 
  }

  const menu = (
    <Menu className={classes.menu}>
      <Menu.Item key='0' className={`${classes.menuItem} ${classes.settingsItem}`}>
        <a href='/Settings'>Settings</a>
      </Menu.Item>
      <Menu.Item key='1' className={classes.menuItem}>
        <div onClick={() => dispatch(logOut())}>
          Logout
        </div>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className={classes.wrapper}>
      {!!props.onExport && props.isCsvExport &&
          <div 
            className={classes.exportBtn}
            onClick={props.onExport}
          >
            Export as CSV
          </div>
      }
      {!!props.onExport && !props.isCsvExport &&
        <div 
          className={classes.exportBtnIcon}
          onClick={props.onExport}
        >
          <ExportIcon />
        </div>
      }

      {newNotifications?.length ? (
        <Popover
          content={<Notifications/>}
          overlayClassName='notifications'
          trigger='click'
          arrowPointAtCenter
          placement='bottomRight'
          onVisibleChange={visible => {
            if (visible) seeNewNotifications()
          }}
        >
          {newNotifications.some(n => !n.is_seen) ? (
            <NotificationBadged
              className={classes.notificationIcon}
              onClick={() => dispatch(getNewNotifications(false))}
            />
          ) : (
            <Notification
              className={classes.notificationIcon}
              style={{cursor:'default'}}
              onClick={() => dispatch(getNewNotifications(false))}
            />
          )}
        </Popover>
      ) : (
        <Notification
          className={classes.notificationIcon}
          style={{cursor:'default'}}
          onClick={() => dispatch(getNewNotifications(false))}
        />
      )}

      <div className={classes.avatarAreaWrapper}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div style={{display:'flex', alignItems:'center'}}>
            <img
              alt='avatar'
              className={classes.avatar}
              src={userAvatar ? `${userAvatar}?${moment()}` : defaultAvatar}
            />
            <div className={classes.userName} >
              {userName} <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

const Notifications = () => {
  const dispatch = useDispatch()
  const newNotifications = useSelector(store => store.notificationsReducer.newNotifications)

  const hideNotification = (notification) => {
    dispatch(removeNotification(notification))
  }

  return (
    <div style={{maxHeight: `${window.innerHeight - 200}px`, overflowY:'auto', padding: '15px 20px 0px 20px'}}>
      {_.orderBy(newNotifications, ['is_seen'], ['asc']).map((notif, index) => (
        <div
          key={notif.id}
          className={classes.notifWrapper}
        >
          {!notif.is_seen && <NewNotificationIcon style={{position:'absolute', left:'-22px'}} />}
          <div className={classes.notifTitle}>{notif.notification_title}</div>
          <CloseIcon
            style={{position:'absolute', right:'-10px', top:'0px', cursor:'pointer'}}
            onClick={() => hideNotification(notif)}
          />
          <div>{notif.notification_content}</div>
          {index !== newNotifications?.length - 1 && <Divider style={{margin: '10px 0px 0px 0px'}}/>}
        </div>
      ))}
    </div>
  )
}

export default UserInfoArea

import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import classes from './Login.module.css'
import {PrimaryButton} from '../common/buttons/buttons'
import { Input } from '../common/Input/Input'

const LoginForm = (props) => {
  const initialValues = {   
    password: '',
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
  })

  const onSubmit = (loginData) => {
    props.signIn(loginData)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form className={classes.formWrapper}>
        <Input         
          label='Email'
          name='email'
          placeholder='Enter your email'
        />

        <Input 
          label='Password'
          name='password'
          placeholder='Enter your password'
          InputProps={{ type:'password' }}
        />
        <div 
          className={classes.loginError} 
          style={{ display: props.loginError ? 'block' : 'none'}}
        >
          {props.loginError?.message ? (
            props.loginError?.message
          ) : (
            'An unexpected error has occurred'
          )}
        </div>
        <PrimaryButton htmlType='submit' text='SIGN IN' style={{height:'36px', width: '132px'}}/>
      </Form>
    </Formik>
  )
}

export default LoginForm

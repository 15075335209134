import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Users from './Users'
import { deactivateUser, getUserList } from '../../store/usersReducer'
import { getCurrentUserRoles } from '../../helpers/localStorage'

const UsersContainer = () => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.usersReducer.userList)
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn)
  const [tableRows, setTableRows] = useState()
  const [modalData, setModalData] = useState({isOpen: false})
  const [selectedUserType, setSelectedUserType] = useState('All')

  useEffect(() => {
    dispatch(getUserList())
  }, [dispatch])

  useEffect(() => {
    setTableRows(users)
  }, [users])

  const search = (value) => {
    const searchResults = users.filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase()))
    if (!value) {
      setTableRows(users)
    }
    setTableRows(searchResults)
  }

  const selectUserType = (val) => {
    if (val === 'All') {
      setTableRows(users)
    } else {
      setTableRows(users.filter(user => val === 'Active' ? user.isActive : !user.isActive))
    }
    setSelectedUserType(val)
  }

  const removeUser = (userId, deleteStakeholder) => {
    dispatch(deactivateUser(userId, deleteStakeholder))
  }

  const currentUserRoles = getCurrentUserRoles()
  const isAdmin = currentUserRoles == null ? false : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin" || role.code.toLowerCase() === "customer admin")

  const userTypeOptions = [
    {id: 1, name: 'All', value: 'All'},
    {id: 2, name: 'Active', value: 'Active'},
    {id: 3, name: 'Inactive', value: 'Inactive'},
  ]

  if(!isAdmin && isSignedIn) {
    return <Redirect to='/StakeholderMap' />
  }

  return (
    <Users 
      removeUser={removeUser}
      modalData={modalData}
      search={search}
      setModalData={setModalData}
      tableRows={tableRows}
      selectedUserType={selectedUserType}
      selectUserType={selectUserType}
      userTypeOptions={userTypeOptions}
    />
  )
}

export default UsersContainer

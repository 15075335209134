import React, { useState } from 'react'
import classes from './WorkerCard.module.css'
import Tooltip from './../../../common/Tooltip/Tooltip'
import WorkerDefaultIcon from '../../../common/WorkerDefaultIcon'
import { getCurrentUserRoles } from '../../../../helpers/localStorage'

const workersCards = (workers, connections, isCirclesView, isPageBlocked) => {
  const workersData = workers.map(worker => {
    return {
      id: `${worker.id}`,
      data: {label: <WorkerCard {...worker} />},
      position: worker.position,
      className: classes.WorkerCardWrapper,
      draggable: !isCirclesView && !isPageBlocked
    }
  })
  return [...workersData, ...connections]
}

const WorkerCard = (worker) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isCardMoving, setIsCardMoving] = useState(false)
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  return (
    <Tooltip
      visible={isTooltipVisible && !isCardMoving}
      title={ 
        <>     
          <div>Full name: {worker.fullName}</div>
          {isUser && worker.isSensitiveContact ? (
            <></>
          ) : (
            <>
              <div>Phone: {worker.phone}</div>
              <div>Email: {worker.email}</div></>
          )}
        </>
      }
    >
      <div 
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onMouseDown={() => setIsCardMoving(true)} 
        onMouseUp={() => setIsCardMoving(false)}
      >
        <WorkerDefaultIcon className={classes.avatar} color={worker.color}/>
        <h1 style={{fontWeight: 600}}>
          {worker.fullName}
        </h1>
        <h2>
          {worker.roleTitle}
        </h2>
      </div>
    </Tooltip>
  )
}

export default workersCards



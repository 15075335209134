import React, { useEffect, useState } from 'react'
import classes from './CustomerDetails.module.css'
import { PrimaryButton } from '../../common/buttons/buttons'
import Search from '../../common/Search/Search'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import getColumns from '../../common/UsersTable/getColumns'
import UserModal from '../../common/UsersTable/UserModal'
import { validateCustomerUser } from '../../../store/customersReducer'
import { setTableColumns } from '../../../store/tablesReducer'

const UsersTable = (props) => {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  useEffect(() => {
    !!props.usersTableRows && setRows(props.usersTableRows)
  }, [props.usersTableRows])

  // const addUserToRemovingList = (userId) => {
  //   if (props.addedTenantUsers.some(user => user.userId === userId)) {
  //     props.setAddedTenantUsers(props.addedTenantUsers.filter(user => user.userId !== userId))
  //   } else if (props.editedTenantUsers.some(user => user.userId === userId)) {
  //     props.setEditedTenantUsers(props.editedTenantUsers.filter(user => user.userId !== userId))
  //   } else {
  //     props.setRemovedTenantUsers([...props.removedTenantUsers, userId])
  //   }
  //   setRows(rows.map(r => r.userId === userId ? {...r, isActive: false} : r))
  // }

  // const addUserToAddingList = (userData) => {
  //   const tempId = v4()
  //   dispatch(validateCustomerUser(userData))
  //     .then(res => {
  //       if (res) {
  //         props.setAddedTenantUsers([...props.addedTenantUsers, {...userData, userId: tempId}])
  //         setRows([...rows, {...userData, userId: tempId}])
  //         props.setUsersModalData({isOpen: false})
  //       }
  //     })
  // }

  // const addUserToEditingList = (userData, userId) => {
  //   dispatch(validateCustomerUser(userData, userId))
  //     .then(res => {
  //       if (res) {
  //         if (props.addedTenantUsers.some(user => user.userId === userId)) {
  //           props.setAddedTenantUsers(props.addedTenantUsers.map(user => user.userId === userId ? {...user, ...userData} : user))
  //         } else if (props.editedTenantUsers.some(user => user.userId === userId)) {
  //           props.setEditedTenantUsers(props.editedTenantUsers.map(user => user.userId === userId ? {...user, ...userData} : user))
  //         } else {
  //           props.setEditedTenantUsers([...props.editedTenantUsers, {...userData, userId}])
  //         }
  //         setRows(rows.map(r => r.userId === userId ? {...r, ...userData} : r))
  //         props.setUsersModalData({isOpen: false})
  //       }
  //     })
  // }

  const addUser = (userData, userId) => {
    dispatch(validateCustomerUser(userData, userId))
      .then(res => !!res && props.saveCustomerUser(userData, userId))
      .then(res => !!res && props.setUsersModalData({isOpen: false}))
  }

  const tableAppearance = useSelector(state => state.tablesReducer?.editCustomerUsersTable)
  const apiRef = useGridApiRef()

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef?.current?.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('editCustomerUsersTable', colsData))
  }

  return (
    <>
      <div style={{height: '500px'}}>
        <div className={classes.titleArea} >
          <Search onChange={event => props.onUserSearch(event.target.value)} style={{height:'43px'}}/>
          <PrimaryButton 
            text='ADD USER' 
            onClick={() => props.setUsersModalData({isOpen: true})}
            style={{height:'43px', marginLeft:'20px'}}
          />
        </div>
        <div className={classes.tableWrapper}>
          <DataGridPro
            getRowId={e => e.userId}
            rows={rows} 
            apiRef={apiRef}
            onColumnWidthChange={(e) => saveTableColumns()}
            columns={tableAppearance.length ? tableAppearance : getColumns(props.setUsersModalData, props.removeCustomerUser, rows)} 
            rowCount={props.usersTableRows?.length || 0}
            pagination
            setPage={page}
            onPageChange={(e) => setPage(e.pageCount - 1)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            style={{borderRadius:'15px', border:'none'}}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
        {props.usersModalData.isOpen &&  
          <UserModal
            modalData={props.usersModalData}
            setUsersModalData={props.setUsersModalData}
            roleOptions={props.roleOptions}
            addUser={addUser}
            editUser={props.updateCustomerUserData}
          />
        }
      </div>
    </>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default UsersTable

import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import classes from './NewWorkerModal.module.css'
import {Input, StyledInput} from '../../../../common/Input/Input'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import {AddOrganisationSelect} from '../../../../common/Select/Select'
import Slider from '../../../../common/Slider/Slider'
import Switch from '../../../../common/Switch/Switch'
import FloatLabel from '../../../../common/FloatLabel/FloatLabel'
import OrganizationModalContainer from '../../../../OrganizationsList/OrganizationModal/OrganizationModalContainer'

const NewWorkerModalForm = (props) => {
  const [orgModalData, setOrgModalData] = useState({isOpen: false})
  const [isOrgSelectOpen, setIsOrgSelectOpen] = useState(false)

  let clearForm = () => {}
  const history = useHistory()

  useEffect(() => {
    return clearForm()
  }, [props.isWorkerModalOpen])

  const initialValues = {
    firstNameKnownAs: '',
    firstName: '',
    lastName: '',
    organizationId: 'none',
    projectRole: '',
    interest: null,
    influence: null,
    support: null
  }

  const validationSchema = Yup.object({
    firstNameKnownAs: Yup.string()
      .required('First name (Known as) is required'),
    organizationId: Yup.string()
      .required('Organisation is required')
      .notOneOf(['none'], 'Organisation is required'),
  })

  const onSubmit = (formData) => {
    props.addNewWorker(formData)
  } 

  const generateFirstName = (value, setFieldValue) => { 
    setFieldValue('firstNameKnownAs', value)
    setFieldValue('firstName', value)
  }

  const goToFullForm = (values) => {
    props.setDataForFullForm(values)
    history.push('/StakeholderMap/StakeholderForm')
    props.closeModal()
  }

  return (
    <>
      <Formik 
        initialValues={initialValues} 
        validationSchema={validationSchema} 
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      > 
        {({ resetForm, values, setFieldValue, status, isSubmitting, setSubmitting, setStatus, errors}) => {
          // const isCompanyEmpty = values.organizationId === 'none' && !values.newOrganizationName
          // if (isSubmitting && isCompanyEmpty){
          //   setSubmitting(false)
          //   setStatus('empty company')
          // }
          clearForm = resetForm
          if (
            (props.error === 'Organisation with this name already exists' && values.organizationId !== 'none')
          ) {
            props.setError(null)
          }
          return (
          <Form>
            <div className={classes.inputsArea}>
              <PrimaryButton 
                text='FULL FORM'
                onClick={() => goToFullForm(values)}
                style={{marginBottom: '30px', marginTop: '15px'}}
              />
              <Field
                disabled={false}
                InputLabelProps={ { shrink: true } }
                component={StyledInput}    
                onChange={(event) => generateFirstName(event.target.value, setFieldValue)}
                InputProps={{classes:{root:'autoWidthInput'}}}
                label='First name (Known as)*'
                name='firstNameKnownAs'
                placeholder='First Name (known as)'
                variant='outlined'
                style={{width:'328px'}}
              />
              <Input        
                label='First name'
                name='firstName'
                placeholder='First name'
              />
              <Input        
                label='Surname'
                name='lastName'
                placeholder='Surname'
              />
              {/* <Divider 
                orientation='left'
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  color: status === 'empty company' ? 'red' : 'black'
                }}
              >
                Organisation*
              </Divider> */}
              <FloatLabel
                required={true}
                value={values.organizationId}
                placeholder='Organisation'
                label='Organisation'
                isFocused={isOrgSelectOpen}
                hasError={!!errors?.organizationId}
                style={{width:'328px', marginRight: '10px', marginBottom: !!errors?.organizationId ? '15px' : '40px'}}
                input={
                  <AddOrganisationSelect
                    options={props.organizationOptions}
                    value={values.organizationId}
                    onChange={(val) => setFieldValue('organizationId', val)}
                    placeholder='Organisation'
                    onBtnClick={() => setOrgModalData({isOpen: true})}
                    className={values.organizationId === 'none' ? 'placeholderIsShown' : ''}
                    error={errors?.organizationId}
                    onDropdownVisibleChange={e => setIsOrgSelectOpen(e)}
                  />
                }
              />
              {/* <Field
                disabled={false}
                component={StyledSelectForFormik }
                name='organizationId'
                variant='outlined'
                select={true}
                value={values.organizationId}
                InputLabelProps={ { shrink: true } }
                onChange={(event) => setCompany('organizationId', event.target.value, setFieldValue)}
                InputProps={{
                  className:values.organizationId === 'none' ? 'placeholderIsShown' : null
                }}
              >
                {props.organizationOptions.map(option => {
                  return (
                    <MenuItem disabled={option.disabled} key={option.id} value={option.value}>
                      {option.title}
                    </MenuItem>
                  )
                })}
              </Field>
              <div style={{marginBottom:'30px'}}>or Create a New organisation</div>
              <Field
                disabled={false}
                InputLabelProps={ { shrink: true } }
                component={StyledInput}    
                onChange={(event) => setCompany('newOrganizationName', event.target.value, setFieldValue)}
                InputProps={{classes:{root:'autoWidthInput'}}}
                name='newOrganizationName'
                placeholder='New organisation'
                variant='outlined'
                style={{width:'328px'}}
              />
              {status === 'empty company' && 
                <div style={{color:'red', marginBottom: '10px', fontSize:'12px'}}>
                  Organisation is required
                </div>
              } */}
              {/* <Divider style={{marginTop: '-5px'}}/> */}
              <Input        
                label='Project role title'
                name='projectRole'
                placeholder='Project role title'
              />
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Interest</span>}
                checked={values.interest !== null}
                onChange={() => {
                  values.interest !== null 
                    ? setFieldValue('interest', null)
                    : setFieldValue('interest', 1)
                }}
                style={{width:'328px'}}
              />
              {values.interest !== null &&
                <div style={{width: '70%'}}>
                  <Slider 
                    defaultValue={values.interest}
                    onChange={(_, value) => setFieldValue('interest', value)}
                    value={values.interest} 
                    min={1}
                    max={10}
                  />
                </div>
              }
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Influence</span>}
                checked={values.influence !== null}
                onChange={() => {
                  values.influence !== null 
                    ? setFieldValue('influence', null)
                    : setFieldValue('influence', 1)
                }}
                style={{width:'328px'}}
              />
              {values.influence !== null &&
                <div style={{width: '70%'}}>
                  <Slider 
                    defaultValue={values.influence}
                    onChange={(_, value) => setFieldValue('influence', value)}
                    value={values.influence} 
                    min={1}
                    max={10}
                  />
                </div>
              }
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Support</span>}
                checked={values.support !== null}
                onChange={() => {
                  values.support !== null 
                    ? setFieldValue('support', null)
                    : setFieldValue('support', 0)
                }}
                style={{width:'328px'}}
              />
              {values.support !== null &&
                <div style={{width: '70%'}}>
                  <Slider 
                    defaultValue={values.support}
                    onChange={(_, value) => setFieldValue('support', value)}
                    value={values.support} 
                    min={-5}
                    max={5}
                  />
                </div>
              }
            </div>
            <div className={classes.formFooter}>
              {/* <PrimaryButton text='FULL FORM' onClick={() => goToFullForm(values)}/> */}
              <div>
                <SecondaryButton
                  onClick={() => props.closeModal()}
                  style={{width:'100px', height: 'auto'}}
                  text='Cancel' 
                />
                <PrimaryButton 
                  className={classes.submitBtn}
                  style={{width:'100px', height: 'auto'}}
                  text='SAVE' 
                  htmlType='submit'
                  loading={props.isLoading}
                />
              </div>
            </div>
          </Form>
        )}}
      </Formik>
      {orgModalData.isOpen &&
        <OrganizationModalContainer
          setSelectedIsActive={() => {}}
          organizationModalData={orgModalData}
          setOrganizationModalData={setOrgModalData}
        />
      }
    </>
  )
}

export default NewWorkerModalForm

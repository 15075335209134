import './App.less'
import { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {Redirect, Route, useLocation, Switch} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Footer from './components/Footer/Footer'
import NavBarContainer from './components/NavBar/NavBarContainer'
import {turnOnConnectionStrength} from './store/stakeholderMapReducer'
import { openNotification } from './components/common/ErrorAlert/ErrorAlert'
import { setError } from './store/errorsReducer'
import { getNewNotifications } from './store/notificationsReducer'
import routes from './routes'

const App = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const error = useSelector(state => state.errorsReducer.error)

  props.turnOnConnectionStrength()

  useEffect(() => {
    dispatch(getNewNotifications(false))
  }, [dispatch])

  useEffect(() => {
    if (error) openNotification(error, () => dispatch(setError(null)))
  }, [error, dispatch])

  useEffect(() => {
    const pageTitles = [
      {url: '/StakeholderMap', title: 'Stakeholder Map'},
      {url: '/SupportChart', title: 'Support Chart'},
      {url: '/InfluenceMatrix', title: 'Influence Matrix'},
      {url: '/Projects', title: 'Projects'},
      {url: '/Contacts', title: 'Contacts'},
      {url: '/Organisations', title: 'Organisations'},
      {url: '/Tenants', title: 'Tenants'},
      {url: '/Users', title: 'Users'},
      {url: '/Settings', title: 'Settings'},
      {url: '/RecordEngagement', title: 'Record Engagement'},
      {url: '/ViewEngagement', title: 'View Engagement'},
      {url: '/Engagements', title: 'Engagements'},
      {url: '/UpcomingEngagements', title: 'Upcoming Engagements'},
      {url: '/ForgotPassword', title: 'Forgot Password'},
      {url: '/Templates', title: 'Templates'},
    ]
    const currentTitle = pageTitles.find(item => location.pathname.includes(item.url))?.title || 'Stakeholder System'
    document.title = `Brickfield | ${currentTitle}`
  }, [location.pathname])

  return (
    <>
      {location.pathname === '/' && <Redirect to='/StakeholderMap'/>}

      <Switch>
          {routes.map(({path, component, exact, auth, id, desktopOnly}) => {
            return auth ? (
              <PrivateRoute
                key={id}
                path={path}
                component={component}
                exact={exact}
                auth={auth}
                desktopOnly={desktopOnly}
              />
            ) : (
              <Route key={id} path={path} exact={exact} render={() => component} />
            )
          })}
      </Switch>
    </>
  )
}

const mapStateToProps = (state) => ({
  isSignedIn: state.authReducer.isSignedIn
})

const PrivateRoute = ({path, component, exact, auth, desktopOnly}) => {
  const currentUserRoles = useSelector(store => store.authReducer.userRoles)
  const isLoggedIn = useSelector(state => state.authReducer.isSignedIn)
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  const isSystemAdmin = currentUserRoles?.some(role => role.code.toLowerCase() === "system admin")
  const systemAdminOnlyPaths = ['/Tenants', '/Templates', '/Users']

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        if (!isLoggedIn && auth) {
          return <Redirect to='/login'/>
        } else if ((isLoggedIn && !auth) || (window.innerWidth < 1367 && desktopOnly)) {
          return <Redirect to={isSystemAdmin ? '/Tenants' : '/StakeholderMap'}/>
        } else if (!!isSystemAdmin && !systemAdminOnlyPaths.some(adminPath => path.includes(adminPath))) {
          return <Redirect to={'/Tenants'}/>
        } else if (!isSystemAdmin && systemAdminOnlyPaths.some(adminPath => path.includes(adminPath))) {
          return <Redirect to={'/StakeholderMap'}/>
        } else {
          return (
            <div className='appWrapper'>
              {!isMobileScreen && <NavBarContainer/>}
              <main>
                {component}
              </main>
              <Footer className='footer'/>
            </div>
          )
        }
      }}
    />
  )
}

export default connect(mapStateToProps, {turnOnConnectionStrength})(App)

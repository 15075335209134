import React from 'react'
import classes from './ContactInformation.module.css'
import CustomDivider from './../../../../common/CustomDivider/CustomDivider'
import EmailAreaContainer from './EmailArea/EmailAreaContainer'
import PhoneAreaContainer from './PhoneArea/PhoneAreaContainer'
import { Input, TextAreaField } from '../../../../common/Input/Input'
import { SelectForFormik } from '../../../../common/Select/Select'

const ContactInformation = (props) => {
  const stakeholderTypeOptions = [
    {id: 0, value: 'none', title: 'Contact type', disabled: true},
    {id: 1, value: 'staff', title: 'Staff'},
    {id: 2, value: 'interim', title: 'Interim'},
    {id: 3, value: 'consultant', title: 'Consultant'},
  ]
  const notesSymbolsLeft = 255 - props.values?.notes?.length

  return (
    <>
      <CustomDivider text='Contact Information' textStyle={{width:'190px'}} style={{margin: '30px 0px 26px 0px'}}/>
      <div className={classes.inputsWrapper}>
        <EmailAreaContainer values={props.values} setFieldValue={props.setFieldValue}/>
        <PhoneAreaContainer values={props.values} setFieldValue={props.setFieldValue}/>
        <div className={classes.doubleInputsRow}>
          <Input        
            label='Job Title'
            name='jobTitle'
            placeholder='Job Title'
            className='autoWidthInput'
          />
          <Input        
            label='Department'
            name='department'
            placeholder='Department'
            className='autoWidthInput'
          />
        </div>
        <div className={classes.doubleInputsRow}>
          <Input        
            label='Grade'
            name='grade'
            placeholder='Grade'
            className='autoWidthInput'
          />
          <Input        
            label='Office Location'
            name='officeLocation'
            placeholder='Office Location'
            className='autoWidthInput'
          />
        </div>
        <div className={classes.doubleInputsRow}>
          <SelectForFormik      
            label='Contact type'
            name='empType'
            options={stakeholderTypeOptions}
            className={props.values?.empType === 'none' ? 'placeholderIsShown InputAutoWidth' : 'InputAutoWidth'}
          />
          <Input  
            className='autoWidthInput'      
            label='LinkedIn profile'
            name='linkedInProfile'
            placeholder='LinkedIn profile'
          /> 
        </div>
        <div className={classes.notesInput}>
          <TextAreaField
            className='autoWidthInput'     
            label='Notes'
            name='notes'
            placeholder='Notes'
            multiline={true}
            rows={5}
            inputProps={{
              maxLength: 255
            }}
          />
        </div> 
      </div>
      {!!props.values?.notes?.length &&
        <div style={{marginTop:'-15px', color:'#959595'}} >
          {notesSymbolsLeft} {notesSymbolsLeft === 1 ? 'symbol' : 'symbols'} left
        </div>
      }
    </>
  )
}

export default ContactInformation


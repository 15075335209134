import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import moment from 'moment'
import _ from 'lodash'
import { Popconfirm, Slider } from 'antd'
import { pageDefaultHeight, tableWidth } from '../../common/sizes'
import UserInfoArea from '../../common/UserInfoArea/UserInfoArea'
import Select from '../../common/Select/Select'
import CustomDivider from '../../common/CustomDivider/CustomDivider'
import classes from './ViewEngagement.module.css'
import { getProjectList, getProjectStakeholdersTC, selectProjectTC } from '../../../store/projectsReducer'
import { deleteEngagementTC, getProjectEngagementsTC, getStakeholderEngagementsTC } from '../../../store/engagementReducer'
import Spinner from '../../common/Spinner/Spinner'
import {ReactComponent as NoDataImg} from './../../../img/noDataImg.svg'
import { mixedPDFExport } from '../../../helpers/exportHelper'
import ProjectSelect from '../../common/ProjectSelect/ProjectSelect'
import {ReactComponent as DeleteIcon} from './../../../img/icons/deleteIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../img/icons/exclamationCircle.svg'

const ViewEngagement = () => {
  const dispatch = useDispatch()
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const projects = useSelector(state => state.projectsReducer.projectList)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  const stakeholderEngagements = useSelector(state => state.engagementReducer.stakeholderEngagements)
  const selectedProjectData = useSelector(state => state.projectsReducer.selectedProjectData)

  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedContactEngagements, setSelectedContactEngagements] = useState(null)
  const [columns, setColumns] = useState([])
  const [lastContact, setLastContact] = useState('')
  const [timelineMarks, setTimelineMarks] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTimeline, setSelectedTimeline] = useState(1)
  const [error, setError] = useState(null)

  useEffect(() => {
    dispatch(getProjectList())
    .then((resp) => {
      if (!resp?.length) {
        setError('noProjects')
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (!!selectedProject) {
      setError('')
      dispatch(getProjectEngagementsTC(selectedProject, true))
      dispatch(getProjectStakeholdersTC(selectedProject))
        .then(resp => {
          const availableStakeholders = resp?.filter(stk => !stk.isProjectTeamMember)
          if (!resp?.length || !availableStakeholders.length) {
            setError('noStakeholders')
          } else if (!!availableStakeholders[0]?.stakeholderId) {
            selectContact(availableStakeholders[0]?.stakeholderId)
          }
        })
    }
    // eslint-disable-next-line
  }, [selectedProject, dispatch])

  const getSelectProjectOptions = () => {
    const options = projects.map(project => ({id: project.projId, value: project.projId, name: project.name}))
    return _.orderBy(options, [user => user.name.toLowerCase()], ['asc'])
  }

  const getStakeholderOptions = () => {
    const options = projectStakeholders
      ?.filter(stk => !stk.isProjectTeamMember)
      ?.map(stk => ({id: stk.stakeholderId, value: stk.stakeholderId, name: stk.fullName}))
    return _.orderBy(options, [user => user.name.toLowerCase()], ['asc'])
  }

  const selectProject = useCallback((value) => {
    dispatch(selectProjectTC(value, projects))
    setSelectedContact(null)
    setIsLoading(false)
  },[projects, dispatch])

  const selectContact = (stkId) => {
    if (!!stkId) {
      setIsLoading(true)
      !!stkId && setSelectedContact(stkId)
      !!stkId && dispatch(getStakeholderEngagementsTC(stkId))
        .then(() => setIsLoading(false))
    }
  }

  const timelineOptions = [
    {id: 1, value: 1,  name: 'Last 4 weeks'},
    {id: 2, value: 2,  name: 'Last 2 months'},
    {id: 3, value: 3,  name: 'Last 3 months'},
    {id: 4, value: 6,  name: 'Last 6 months'},
  ]

  useEffect(() => {
    if (!!selectedContact) {
      const projectEngagements = stakeholderEngagements
        .filter(eng => eng.project_id === selectedProject)
        .sort((e1, e2) => {
          const date1 = e1?.is_completed ? e1?.engagement_date : e1?.engagement_plan_date
          const date2 = e2?.is_completed ? e2?.engagement_date : e2?.engagement_plan_date
          return moment.utc(date2).diff(moment.utc(date1))
        })
      setSelectedContactEngagements(projectEngagements)
      setColumns(getColumns(projectEngagements))
    } else {
      setColumns(getColumns([]))
    }
    // eslint-disable-next-line
  }, [stakeholderEngagements, projectStakeholders])

  useEffect(() => {
    const calcPassedTimeInPercentage = (dates) => {
      const result = {}
        let timelinePeriodStart
        if (selectedTimeline === 1) {
          timelinePeriodStart = moment().subtract(4, 'weeks').startOf('week').add(1, 'day')
        } else {
          timelinePeriodStart = moment().subtract(selectedTimeline,'months')
        }
        const datesFromTimeline = dates.filter(date => moment(date).isAfter(timelinePeriodStart))
        const start = moment().subtract(selectedTimeline,'months')
        const end = moment.utc()
        const timeBetweenStartAndEnd = (moment(end) - moment(start))
        datesFromTimeline.forEach(date => {
          const timeBetweenStartAndToday = moment(date) - moment(start)
          result[Math.round(timeBetweenStartAndToday / timeBetweenStartAndEnd * 100)] = moment(date).format('DD MMM')
        })
      return result
    }

    const getTimelineMarks = () => {
      const dates = selectedContactEngagements?.filter(eng => !!eng.is_completed)
        .map(eng => eng.engagement_date ? moment(eng.engagement_date) : moment(eng.engagement_plan_date))
      return calcPassedTimeInPercentage(dates || [])
    }

    const timelineMarksData = getTimelineMarks()
    const timelinePointsAmount = Object.keys(timelineMarksData)?.length
    setTimelineMarks(timelineMarksData)
    if (!!timelinePointsAmount) {
      const maxDateEngagement =  moment.max(selectedContactEngagements?.map(eng => moment(eng.last_engagement_date)))
      const lastEngagementDate = moment()?.diff(moment(maxDateEngagement), "days")
      setLastContact(`${lastEngagementDate} day(s) ago`)
    } else {
      setLastContact('no data')
    }
  }, [selectedContactEngagements, selectedTimeline])

  const exportPage = () => {
    const selectedProjectTitle = selectedProjectData?.name
    const selectedProjectMarking = selectedProjectData?.projectMarking
    const selectedStakeholderName = projectStakeholders.find(stk=> stk.stakeholderId === selectedContact)?.fullName
    mixedPDFExport(
      'viewEngagementsTable',
      'viewEngagementTimeline',
      `${selectedProjectTitle}_Engagements_${selectedStakeholderName}`,
      selectedProjectTitle,
      `View Engagements - ${selectedStakeholderName}`,
      selectedProjectMarking,
      tableWidth + 45
    )
  }

  if (error === 'noProjects') {
    return (
      <>
        <header className={classes.header} style={{display:'flex', justifyContent:'flex-end'}}>
          <UserInfoArea />
        </header>
        <div className='emptyPage' style={{height: pageDefaultHeight}}>
          <NoDataImg />
        </div>
      </>
    )
  }

  if (error === 'noStakeholders') {
    return (
      <div style={{paddingTop: '10px', paddingLeft: '10px'}}>
        <header className={classes.header}>
          <UserInfoArea />
        </header>
        <div className={`emptyPage ${classes.noStakeholdersPage}`} style={{height: pageDefaultHeight}}>
          <ProjectSelect
            options={getSelectProjectOptions()}
            onSelect={(value) => selectProject(value)}
            style={{marginRight:'auto', width:'290px'}}
            value={selectedProject}
          />
          <div style={{margin: 'auto 0px auto -45px'}}>
            No stakeholders in this project
          </div>
        </div>
      </div>
    )
  }

  if (!selectedContact || !!isLoading) {
    return (
      <Spinner style={{minHeight: pageDefaultHeight}}/>
    )
  }

  return (
    <>
      <header className={classes.header}>
        <UserInfoArea onExport={exportPage}/>
      </header>
      <div className={classes.wrapper} style={{minHeight: pageDefaultHeight}}>
        <div style={{marginLeft: '45px'}}>
          <ProjectSelect
            options={getSelectProjectOptions()}
            onSelect={(value) => selectProject(value)}
            style={{marginRight:'20px', width:'290px'}}
            value={selectedProject}
          />
          <Select 
            options={getStakeholderOptions()}
            onSelect={(value) => selectContact(value)}
            style={{marginRight:'20px', width:'290px'}}
            value={selectedContact}
          /> 
        </div>
        <h1 className={classes.pageTitle}>
          VIEW ENGAGEMENT
        </h1>
        <div className={classes.tableWrapper}>
          <DataGridPro
            getRowId={e => e.id || null}
            rows={selectedContactEngagements|| []} 
            columns={columns}
            rowCount={selectedContactEngagements|| 0}
            disableSelectionOnClick
            style={{borderRadius:'15px', border:'none'}}
          />
        </div>
        <div id='viewEngagementTimeline' style={{padding:'0px 40px 30px 40px'}}>
          <CustomDivider text='Engagement Timeline' textStyle={{width:'200px'}}/>
          <div className={classes.selectArea}>
            <Select 
              options={timelineOptions}
              onSelect={(value) => setSelectedTimeline(value)}
              style={{width: '200px', marginRight: '15px'}}
              value={selectedTimeline}
              className='smallSelect'
            />
            <div className={classes.lastContact}>
              Last contact: {lastContact} 
            </div>
          </div>
          <div>
            {!!Object.keys(timelineMarks).length ? (
              <div style={{position: 'relative'}}>
                <div
                  style={{
                    color: 'rgb(164 164 164)',
                    position: 'absolute',
                    left: '-30px',
                    top: '-10px',
                    whiteSpace: 'pre'
                  }}
                >
                  {selectedTimeline === 1 ? (
                    moment().subtract(4, 'weeks').startOf('week').add(1, 'day').format('DD[\n]MMM')
                  ) : (
                    moment().subtract(selectedTimeline,'months').format('DD[\n]MMM')
                  )}
                </div>
                <Slider
                  marks={timelineMarks}
                  defaultValue={Object.keys(timelineMarks).length === 1 ? 0 : 100}
                  disabled
                  className='timeline'
                  handleStyle={{display: 'none'}}
                />
                <div
                  style={{
                    color: 'rgb(164 164 164)',
                    position: 'absolute',
                    right: '-30px',
                    top: '-10px',
                    whiteSpace: 'pre'
                  }}
                >
                  {moment().utc().format('DD[\n]MMM')}
                </div>
              </div>
            ) : (
              <div style={{margin: '0 auto', fontSize: '16px', width: '250px'}}>
                No completed engagements
              </div>
            )}
          </div>
        </div>
        <TableForExport
          style={{display:'none'}}
          selectedContactEngagements={selectedContactEngagements}
          projectStakeholders={projectStakeholders}
        />
      </div>
    </>
  )
}

const TableForExport = (props) => {
  return (
    <table id='viewEngagementsTable' style={props.style}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Comments</th>
          <th>Completed</th>
        </tr>
      </thead>
      <tbody>
        {props.selectedContactEngagements?.map(row => {
          const date = !!row?.is_completed ? row?.engagement_date : row?.engagement_plan_date
          return (
            <tr key={row.id}>
              <td>{moment(date).format('DD.MM.YYYY')}</td>
              <td>{row?.comments}</td>
              <td>{row?.is_completed ? 'yes' : 'no'}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )    
}

const getColumns = (engagements) => {
  const columnSize = 0.28
  const actionColumnSize = 0.12
  const columnWidth = tableWidth * columnSize
  const actionColumnWidth = tableWidth * actionColumnSize

  return [
    { field: 'start_date',
      headerName: 'Date',
      sortComparator: (v1, v2, e1, e2) => {
        const e1Data = engagements.find(e => e.id === e1.id)
        const e2Data = engagements.find(e => e.id === e2.id)
        const date1 = e1Data?.is_completed ? e1Data?.engagement_date : e1Data?.engagement_plan_date
        const date2 = e2Data?.is_completed ? e2Data?.engagement_date : e2Data?.engagement_plan_date
        return moment.utc(date1).format().localeCompare(moment.utc(date2).format())
      },
      renderCell: (params) => {
        const date = !!params.row?.is_completed ? params.row?.engagement_date : params.row?.engagement_plan_date
        return (
          <div>
            {moment(date).format('DD.MM.YYYY')}
          </div>
        )
      },
      width: columnWidth
    },
    { field: 'comments',
      headerName: 'Comments',
      width: columnWidth
    },
    { field: 'is_completed',
      headerName: 'Completed',
      renderCell: (params) => {
        return (
          <div>{params.row?.is_completed ? 'yes' : 'no'}</div>
        )
      },
      width: columnWidth
    },
    { field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: (params) => {
      return (
        <ConfirmDelete
          engagementId={params.id}
        />
      )
    },
    width: actionColumnWidth
  },
  ]
}

const ConfirmDelete = ({engagementId}) => {
  const dispatch = useDispatch()
  const [confirmMessageNumber, setConfirmMessageNumber] = useState(0)

  const deleteEngagement = () => {
    setConfirmMessageNumber(2)
    dispatch(deleteEngagementTC(engagementId))
  }

  return (
    <Popconfirm
      title={'Are you sure to delete this engagement?'}
      onConfirm={deleteEngagement}
      onCancel={() => setConfirmMessageNumber(0)}
      okText='Yes'
      cancelText='No'
      icon={<ExclamationCircle />}
      cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
      okButtonProps={{type: 'default'}}
      visible={confirmMessageNumber === 1}
    >
      <DeleteIcon
        style={{cursor: 'pointer'}}
        onClick={() => setConfirmMessageNumber(1)}
      />
    </Popconfirm>
  )
}

export default ViewEngagement

import moment from 'moment'
import lodash from 'lodash'
import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'

const initialState = {
  projectList:[],
  projectsWithStakeholders: [],
  projectsAllPeriods: [],
  selectedProject: 0,
  selectedProjectData: {},
  projectStakeholders: []
}

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROJECTS_LIST':
      return {...state, projectList: action.projectList || []} 
    case 'SET_PROJECTS_WITH_STAKEHOLDERS':
      return {...state, projectsWithStakeholders: action.projectList} 
    case 'SET_PROJECTS_ALL_PERIODS':
      return {...state, projectsAllPeriods: action.projectsAllPeriods} 
    case 'ADD_PROJECT':
      return {...state, projectList: [...state.projectList, action.project]} 
    case 'UPDATE_PROJECT':
      return {...state, projectList: state.projectList.map(project => {
        if (project.projId === action.updatedProject.projId) {
          return {...project, ...action.updatedProject}
        } else {
          return project
        }
      })} 
    case 'REMOVE_PROJECT':
      return {...state, projectList: state.projectList.filter(project => project.projId !== action.projectId)}
    case 'SET_SELECTED_PROJECT':
      return {...state, selectedProject: action.selectedProject} 
    case 'SET_SELECTED_PROJECT_DATA':
      return {...state, selectedProjectData: action.data} 
    case 'SET_PROJECT_STAKEHOLDERS':
      return {...state, projectStakeholders: action.stakeholders} 
      default: return state
  } 
}

export const setProjectList = (projectList) => ({type: 'SET_PROJECTS_LIST', projectList})
export const setProjectsWithStakeholders = (projectList) => ({type: 'SET_PROJECTS_WITH_STAKEHOLDERS', projectList})
export const setProjectsAllPeriods = (projectsAllPeriods) => ({type: 'SET_PROJECTS_ALL_PERIODS', projectsAllPeriods})
export const addProject = (project) => ({type: 'ADD_PROJECT', project})
export const updateProject = (updatedProject) => ({type: 'UPDATE_PROJECT', updatedProject})
export const removeProject = (projectId) => ({type: 'REMOVE_PROJECT', projectId})
export const setSelectedProject = (selectedProject) => ({type: 'SET_SELECTED_PROJECT', selectedProject})
export const setSelectedProjectData = (data) => ({type: 'SET_SELECTED_PROJECT_DATA', data})
export const setProjectStakeholders = (stakeholders) => ({type: 'SET_PROJECT_STAKEHOLDERS', stakeholders})

export const getProjectList = (getStakeholders = false) => (dispatch, getState) => {
  const dateNow = moment().format('yyyy-MM-DD HH:mm:ss')
  return axios.get('/projects', {params: {
    localDateTime: dateNow,
    stakeholders: getStakeholders
  }})
    .then(response => {
      const selectedProject = getState().projectsReducer.selectedProject
      if(response.status === 200) {
        dispatch(setProjectList(response.data.responses))
        if (selectedProject === 0) {
          const defaultSelectedProject = lodash.orderBy(response.data.responses, [project => project.name.toLowerCase()], ['asc'])[0]
          dispatch(setSelectedProject(defaultSelectedProject?.projId || 0))
          dispatch(setSelectedProjectData(defaultSelectedProject || {}))
        }
      } else {
        dispatch(handleError(response.data))
      }
      return response.data.responses
    })
    .catch(error => dispatch(handleError(error)))
}

export const getProjectsByPeriod = (period, stakeholders = false, userId, source) => (dispatch, getState) => {
  return axios.get(`/projects/parameter`, {
    params: {
      parameter:period,
      stakeholders: stakeholders,
      userId: userId,
    },
    cancelToken: source?.token
  })
    .then(response => {
      const selectedProject = getState().projectsReducer.selectedProject
      if (response.status === 200 ) {
        if(!period) dispatch(setProjectsAllPeriods(response.data.responses))
        if (selectedProject === 0) {
          const activeProjects = response.data.responses.filter(project => moment(project.activeTill).isAfter(moment()))
          const sourceForSelectedProj = activeProjects.length ? activeProjects : response.data.responses
          const defaultSelectedProject = lodash.orderBy(sourceForSelectedProj, [project => project.name.toLowerCase()], ['asc'])[0]
          dispatch(setSelectedProject(defaultSelectedProject?.projId || 0))
          dispatch(setSelectedProjectData(defaultSelectedProject || {}))
        }
        dispatch(setProjectList(response.data.responses))
      } else {
        dispatch(handleError(response.data))
      }
      return response.data.responses
    })
    .catch(error => error.message !== 'cancel' && dispatch(handleError(error)))
}

export const createProject = (newProjectData) => (dispatch) => {
  return axios.post('/projects', newProjectData)
    .then((response) => {
      if (response.status === 201) { 
        dispatch(addProject(response.data.project))
      }
    })
}

export const editProject = (projectData, isSelectedProject) => (dispatch) => {
  return axios.put(`/projects/${projectData.projId}`, projectData)
    .then((response) => {
      if (response.status === 200) {
        dispatch(updateProject({...response.data.project, userView: response.data.userView}))
        !!isSelectedProject && 
          dispatch(setSelectedProjectData(
            {...projectData,
              userView: response.data.userView,
              projectView: response.data.project.projectView
            })
          )
      } else {
        dispatch(handleError(response.data))
      }
    })
}

export const deleteProject = (projectId) => (dispatch, getState) => {
  axios.delete(`/projects/${projectId}`)
    .then(response => {
      if (response.status === 200) {
        const selectedProject = getState().projectsReducer.selectedProject
        dispatch(removeProject(projectId)) 
        if (selectedProject === projectId) {
          const updatedProjects = getState().projectsReducer?.projectList?.filter(p => p?.projId !== projectId)
          const newSelectedProject = lodash.orderBy(updatedProjects, ['asc'])[0]
          dispatch(setSelectedProject(newSelectedProject?.projId || 0))
          dispatch(setSelectedProjectData(newSelectedProject || {}))
        }
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const editStakeholderInProject = (projId, stkId, data) => (dispatch) => {
  return axios.put(`/projects/${projId}/stakeholder/${stkId}/relation`, data)
    .then(response => {
      response.status !== 200 && dispatch(handleError(response.data))
      return response.status === 200 ? true : false
    })
    .catch(error => dispatch(handleError(error)))
}

export const selectProjectTC = (projId, projects) => (dispatch) => {
  const projectData = projects?.find(project => project.projId === projId) || {}
  dispatch(setSelectedProject(projId))
  dispatch(setSelectedProjectData(projectData))
  dispatch(getProjectStakeholdersTC(projId))
}

export const getProjectStakeholdersTC = (projId) => (dispatch) => {
  return axios.get(`/projects/${projId}/stakeholders`)
    .then(response => {
      response.status === 200 
        ? dispatch(setProjectStakeholders(response.data.stakeholders))
        : dispatch(handleError(response.data))
      return response.data.stakeholders || []
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const addStakeholdersToProjectTC = (projId, stakeholderProjectRelations) => (dispatch) => {
  return axios.post(`/projects/${projId}/stakeholders`, {stakeholderProjectRelations})
    .then(response => {
      response.status === 200 
        ? dispatch(setProjectStakeholders(response.data.project?.stakeholders))
        : dispatch(handleError(response.data))
      return response
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export default projectsReducer

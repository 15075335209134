import React, { useState } from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import classes from './UsersTable.module.css'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import Checkbox from './../../common/Checkbox/Checkbox'
import generatePassword from '../../../helpers/passwordGenerator'

const UserModalForm = ({ 
    modalData, 
    editUser, 
    addUser, 
    setUsersModalData,
    roleOptions
  }) => {

  const [isNewPassword, setIsNewPassword] = useState(false)
    
  const initialValues = {
    firstName: modalData.userData?.firstName || '',
    lastName: modalData.userData?.lastName || '',
    email: modalData.userData?.email || '',
    phoneNumber: modalData.userData?.phoneNumber || '',
    password: modalData.userData?.firstName ? '*****' : '',
    roles: modalData.userData?.roles || [],
    isActive: modalData.userData?.isActive !== undefined ? modalData.userData?.isActive : true
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('This field is required'),
    lastName: Yup.string()
      .required('This field is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('This field is required'),
    password: Yup.string()
      .test(password => !/\s/.test(password))
      .required('This field is required'),
  })

  const onSubmit = (formData) => {
    if(modalData.userData) {
      editUser(
        {...formData, password: formData.password === '*****' ? null : formData.password, email: formData.email === '' ? null : formData.email},
        modalData.userData.userId
      )
    } 
    else {
      addUser(formData)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({setFieldValue, values, errors}) => { 
        const onPasswordChange = (e) => {
          setIsNewPassword(false)
          setFieldValue('password', e.target.value)
        }

        return (
          <Form className={classes.form}>
            <div className={classes.inputsPair}>
              <Input 
                name='firstName'
                label='First name*'
                placeholder='First name'
              />
              <Input 
                name='lastName'
                label='Last name*'
                placeholder='Last name'
              />
            </div>
            <div className={classes.inputsPair}>
              <div style={{position:'relative'}}>
                <Input
                  name='password'
                  label='Password*'
                  placeholder='Password'
                  InputProps={{
                    ...(!isNewPassword && { type:'password' }),
                    autoComplete: 'new-password',
                    onChange: onPasswordChange
                  }}
                />
                <div 
                  className={classes.generateBtn}
                  onClick={() => {
                    setFieldValue('password', generatePassword())
                    setIsNewPassword(true)
                  }}
                >
                  Generate
                </div>
              </div>
              
              <Input 
                name='email'
                label='Email*'
                placeholder='Email'
              />
            </div>
            <div className={classes.inputsPair}>
              <Input 
                name='phoneNumber'
                label='Phone number'
                placeholder='Phone number'
              />
            </div>
            
            <div className={`${classes.rolesWrap} ${errors.roles ? classes.rolesWrapError : ''}`}>
              <div className={classes.roleAreaTitle}>
                Roles
              </div>
              {roleOptions.map(role => (
                <Checkbox
                  checked={values.roles?.some(r => r.id === role?.id)}
                  label={role?.code.charAt(0).toUpperCase() + role?.code.slice(1)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('roles', [...values.roles, role])
                    } else {
                      setFieldValue('roles', values.roles.filter(r => r.id !== role?.id))
                    }
                  }}
                />
              ))}
            </div>
            <div className={classes.rolesErrorText}>
              {errors.roles}
            </div>
           
            <Checkbox
              checked={values.isActive}
              label='Active user'
              onChange={() => setFieldValue('isActive', !values.isActive)}
            />
            
            <div className={classes.buttonsArea}>
              <SecondaryButton
                onClick={() => setUsersModalData({isOpen: false})}
                style={{width:'100px', height: 'auto'}}
                text='Cancel' 
              />
              <PrimaryButton 
                className={classes.primaryButton}    
                htmlType='submit' 
                style={{width:'100px', height: 'auto', marginLeft: '15px'}}
                text='SAVE' 
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserModalForm

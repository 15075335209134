import React, {useEffect, useState, useCallback, createRef} from 'react'
import { connect, useSelector } from 'react-redux'
import {setConnections, deleteConnection, removeStakeholderFromProject, undoDeletingTC, deleteAnnotationTC} from '../../../store/stakeholderMapReducer'
import Canvas from './Canvas'
import {removeDeletedItemsPosition, getElementConnections, updateCardPosition, getElementAllViewsPosition, getAnnotationCards} from './CanvasHelper'
import workersCards from './workersCards/workersCards'
import getCompanyTitles from './companyTitles/companyTitle'
import {
  setNewConnectionData, 
  setIsConnectionModalOpen, 
  setIsWorkerModalOpen, 
  setSelectedElement, 
  setIsConnectingActive,
  setIsConnectionInfoModalOpen,
} from '../../../store/canvasReducer'
import {editProject} from '../../../store/projectsReducer' 
import { useReactToPrint } from 'react-to-print'

const CanvasContainer = (props) => {
  const annotations = useSelector((state) => state.stakeholderMapReducer.annotations)

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false)
  const [isPageBlocked, setIsPageBlocked] = useState(false)
  const [canvasData, setCanvasData] = useState([])
  const [lastDeletedItems, setLastDeletedItems] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [selectedAnnotation, setSelectedAnnotation] = useState()

  const isCirclesView = props.selectedView === 'circleView'

  const startCardsConnecting = (connectionData) => {
    if (connectionData.source.includes('annotation') || connectionData.target.includes('annotation')) {
      return
    }
    const isDuplicate = props.connections.some(connection => {
      return (connection.source === connectionData.source || connection.source === connectionData.target) &&
      (connection.target === connectionData.source || connection.target === connectionData.target)
    })
    const isHeaderNode = connectionData.source.includes('header') || connectionData.target.includes('header')
    if (isDuplicate || isHeaderNode) {
      return
    }
    props.setIsConnectionModalOpen(true)
    props.setNewConnectionData(connectionData) 
  }

  const removeElement = (elementsToRemove = [props.selectedElement, ...getElementConnections(props.selectedElement, props.connections)]) => {
    if (elementsToRemove[0] === null || elementsToRemove[0]?.id.includes('title')) {
      return
    } else if (elementsToRemove[0]?.id.includes('line')) {
      setIsDataLoading(true)
      setLastDeletedItems([props.selectedElement])
      return props.deleteConnection(props.selectedElement.id).then((resp) => setIsDataLoading(false))
    } else if (elementsToRemove[0]?.id.includes('annotation')) {
      setIsDataLoading(true)
      setLastDeletedItems([props.selectedElement])
      return props.deleteAnnotationTC(+props.selectedElement.id.split('annotation-')[1])
        .then((resp) => setIsDataLoading(false))
    } else if (!isDeleteConfirmOpen) {
      setIsDeleteConfirmOpen(true)
    } else {
      setIsDataLoading(true)
      setLastDeletedItems([
        props.selectedElement,
        ...getElementConnections(props.selectedElement, props.connections),
        getElementAllViewsPosition(props.selectedProjectData, props.selectedElement)
      ])
      props.removeStakeholderFromProject(props.selectedProject, Number(props.selectedElement.id))
        .then(() => {
          const updatedPositionsProjectView = props.selectedProjectData.projectView?.positions?.filter((item) => removeDeletedItemsPosition(item, props.selectedElement.id, props.projectStakeholders))
          const updatedPositionsUserView = props.selectedProjectData.userView?.positions?.filter((item) => removeDeletedItemsPosition(item, props.selectedElement.id, props.projectStakeholders))
          props.editProject({
            ...props.selectedProjectData,
            projectView: {positions: updatedPositionsProjectView},
            userView: {positions: updatedPositionsUserView},
            stakeholders: props.projectStakeholders.filter(stk => stk.stakeholderId !== Number(props.selectedElement.id)),
            userId: props.userId
          }, true)
        })
        .then(() => {
          setIsDeleteConfirmOpen(false)
          setIsDataLoading(false)
        })
    }
  }

  const undoDeleting = () => {
    setIsDataLoading(true)
    props.undoDeletingTC(lastDeletedItems, props.isStrengthViewOn, props.selectedProject, props.stakeholders)
      .then(() => {
        setIsDataLoading(false)
        setLastDeletedItems([])
      })
  }

  const changeSelectedElement = (element) => {
    if (props.isConnectingActive && props.selectedElement && element && element.id !== props.selectedElement.id) {
      startCardsConnecting({source: props.selectedElement.id, target: element.id})
      props.setIsConnectingActive(false)
    } else {
      !isDeleteConfirmOpen && props.setSelectedElement(element)
    }
  }

  const onNodeDragStop = (_, movedCard) => {
    savePositions(movedCard)
  }

  // const createCircle = (workers, spaceBetweenCircles, company) => {
  //   const calculateCircleRadius = () => {
  //     if (workers.length <= 9) {
  //       return 300
  //     } else if (workers.length <= 16) {
  //       return 500
  //     } else {
  //       return 700
  //     }
  //   }
  //   const circleRadius = calculateCircleRadius()
  //   // title
  //   const smallestX = lodash.minBy(props.workers, 'position.x')
  //   const smallestY = lodash.minBy(props.workers, 'position.y')
  //   const titleMarginX = 650
  //   const titleMarginY = 220
  //   const centerX = ((smallestX.position.x - titleMarginX) /2) + spaceBetweenCircles
  //   const centerY = ((smallestY.position.y - titleMarginY))
  //   const companyTitle = getCompanyTitles([{...company, position: {x: centerX, y: centerY}}], isCirclesView)
  //   // workers
  //   const workersWithCirclePosition = workers.map((worker, index) => {
  //     const frags = 360 / workers.length
  //     const angleInRadians = (frags / 180) * index * Math.PI
  //     const x = circleRadius * Math.cos(angleInRadians) + spaceBetweenCircles
  //     const y = circleRadius * Math.sin(angleInRadians)
  //     return {...worker, position: {x, y}}
  //   })
  //   return {workers: workersWithCirclePosition, title: companyTitle}
  // }
  // const getCirclesViewData = () => {
  //   const calculateSpaceBetweenCircles = (workers) => {
  //     if (workers.length <= 9) {
  //       return 1000
  //     } else if (workers.length <= 16) {
  //       return 1500
  //     } else {
  //       return 2000
  //     }
  //   }
  //   let spaceBetweenCircles = 0
  //   const orgIds = lodash.uniqBy(props.workers, (e) => e.orgId).map(uniq => uniq.orgId)
  //   const circlesViewData = orgIds.map(orgId => {
  //     const workers = props.workers.filter(worker => worker.orgId === orgId)
  //     const title = props.organisationsFromProject.find(company => company.id === String(orgId))
  //     const circle = createCircle(workers, spaceBetweenCircles, title)
  //     const workerCards = workersCards(circle.workers, [], isCirclesView)
  //     const companyTitles = [...circle.title]
  //     spaceBetweenCircles += calculateSpaceBetweenCircles(workers)
  //     return [...workerCards, ...companyTitles]
  //   }) 

  //   return lodash.flatten(circlesViewData)
  // }

  const savePositions = (movedItem) => {
    let updatedPositions
    if (props.selectedProjectData?.[props.selectedView]?.positions.some(pos => pos.id === movedItem.id)) {
      updatedPositions = props.selectedProjectData?.[props.selectedView]?.positions.map(item => {
        return item.id === movedItem.id ? {...item, position: movedItem.position} : item
      })
    } else {
      const startValue = props.selectedProjectData?.[props.selectedView]?.positions 
        ? props.selectedProjectData?.[props.selectedView]?.positions 
        : []
      updatedPositions = [
        ...startValue,
        { id: movedItem.id, position: movedItem.position}
      ]
    }
    props.editProject({
      ...props.selectedProjectData,
      [props.selectedView]: {positions: updatedPositions},
      userId: props.userId
    }, true)
  }

  const toggleIsPageBlocked = useCallback((isBlocked) => {
    const isAdmin = props.userRoles == null 
      ? false
      : props.userRoles?.some(role => role.code.toLowerCase() === "system admin" 
        || role.code.toLowerCase() === "customer admin"
        || role.code.toLowerCase() === "project admin"
      )
    if (!isAdmin && props.selectedView === 'projectView') {
      setIsPageBlocked(true)
    } 
    else {
      setIsPageBlocked(isBlocked)
    }
  }, [props.selectedView, props.userRoles])

  useEffect(() => {
    toggleIsPageBlocked()
  }, [props.selectedView, toggleIsPageBlocked])

  useEffect(() => {
    const companyTitles = getCompanyTitles(props.organisationsFromProject, isCirclesView, isPageBlocked)
    const workersCardsData = workersCards(props.workers, props.connections, isCirclesView, isPageBlocked)
    const updatedCardsPosition = updateCardPosition(
      props.selectedProjectData[props.selectedView]?.positions,
      [...workersCardsData, ...companyTitles]
    )
    const visibleAnnotations = annotations.filter(a => props.selectedView === 'projectView' ? !!a.isProjectViewVisible : a.userId === props.userId)
    const annotationCardsData = getAnnotationCards(visibleAnnotations, props.selectedProjectData[props.selectedView]?.positions, isPageBlocked)

    setCanvasData([...updatedCardsPosition, ...annotationCardsData])
  }, [props.organisationsFromProject, annotations, isPageBlocked, props.workers, props.connections, props.selectedView, props.selectedProjectData, isCirclesView, props.userId])

  const componentRef = createRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => props.setRefData(null),
    documentTitle: `${props.selectedProjectData?.name}_ProjectMap.pdf`,
  })

  useEffect(() => {
    if(props.refData) {
      handlePrint()
      props.setRefData(null)
    }
    // eslint-disable-next-line
  }, [props.refData])

  return (
    <Canvas 
      ref={componentRef}
      elements={canvasData} 
      removeElement={removeElement}
      onNodeDragStop={onNodeDragStop}
      selectedElement={props.selectedElement}
      setIsConnectingActive={props.setIsConnectingActive}
      isConnectingActive={props.isConnectingActive}
      changeSelectedElement={changeSelectedElement}
      setIsConnectionModalOpen={props.setIsConnectionModalOpen}
      startCardsConnecting={startCardsConnecting}
      setIsWorkerModalOpen={props.setIsWorkerModalOpen}
      isWorkerModalOpen={props.isWorkerModalOpen}
      isConnectionInfoModalOpen={props.isConnectionInfoModalOpen}
      setIsConnectionInfoModalOpen={props.setIsConnectionInfoModalOpen}
      isStrengthViewOn={props.isStrengthViewOn}
      isCirclesView={isCirclesView}
      selectedProject={props.selectedProject}
      workersAmount={props.workers.length}
      isDeleteConfirmOpen={isDeleteConfirmOpen}
      setIsDeleteConfirmOpen={setIsDeleteConfirmOpen}
      isPageBlocked={isPageBlocked}
      toggleIsPageBlocked={toggleIsPageBlocked}
      selectedView={props.selectedView}
      isAnnotationModalOpen={isAnnotationModalOpen}
      setIsAnnotationModalOpen={setIsAnnotationModalOpen}
      annotations={props.annotations}
      selectedProjectData={props.selectedProjectData}
      undoDeleting={undoDeleting}
      lastDeletedItems={lastDeletedItems}
      isDataLoading={isDataLoading}
      selectedAnnotation={selectedAnnotation}
      setSelectedAnnotation={setSelectedAnnotation}
    />
  )
}
const mapStateToProps = state => ({
  connections: state.stakeholderMapReducer.connections,
  selectedElement: state.canvasReducer.selectedElement,
  workers: state.stakeholderMapReducer.workers,
  isConnectingActive: state.canvasReducer.isConnectingActive,
  isWorkerModalOpen: state.canvasReducer.workers.isWorkerModalOpen,
  isConnectionInfoModalOpen: state.canvasReducer.connections.isConnectionInfoModalOpen,
  organisationsFromProject: state.stakeholderMapReducer.organisationsFromProject,
  selectedProject: state.projectsReducer.selectedProject,
  selectedProjectData: state.projectsReducer.selectedProjectData,
  userRoles: state.authReducer.userRoles,
  userId: state.authReducer.userId,
  organizationList: state.organizationsReducer.organizations,
  projectStakeholders: state.projectsReducer.projectStakeholders,
  annotations: state.stakeholderMapReducer.annotations,
  stakeholders: state.stakeholdersReducer.stakeholders
})

export default connect(mapStateToProps, 
  { setConnections, 
    setSelectedElement, 
    setNewConnectionData,
    setIsConnectionModalOpen,
    setIsWorkerModalOpen,
    setIsConnectingActive,
    setIsConnectionInfoModalOpen,
    deleteConnection,
    removeStakeholderFromProject,
    editProject,
    undoDeletingTC,
    deleteAnnotationTC,
  })(CanvasContainer)


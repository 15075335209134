import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from './../../helpers/axiosHelper'
import Login from './Login'
import {logIn} from './../../store/authReducer'

const LoginContainer = (props) => {
  const [loginError, setLoginError] = useState(null)
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false)

  if (props.isSignedIn) {
    return <Redirect to='/StakeholderMap' />
  }

  const signIn = (loginData) => {
    axios.post('/users/login', loginData)
      .then(response => { 
        if (!response.data?.roles?.length) {
          setLoginError({message: 'This user has no roles'})
        } else {
          props.logIn({...response?.data, email:loginData.email}) 
        }
      })
      .catch(error => {
        setLoginError(error?.response?.data)
      })
  }

  return (
    <Login 
      loginError={loginError}
      signIn={signIn}
      isAccessModalOpen={isAccessModalOpen}
      setIsAccessModalOpen={setIsAccessModalOpen}
    />
  )
}

const mapSateToProps = (state) => ({
  isSignedIn: state.authReducer.isSignedIn,
})

export default connect(mapSateToProps,{logIn})(LoginContainer)

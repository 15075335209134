import React, { useEffect, useState } from 'react'
import {default as CheckboxMaterial} from '@material-ui/core/Checkbox'

const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(props.checked)
  }, [props.checked])
  
  return (
    <div style={{display: 'inline-flex', alignItems: 'center', ...props.wrapperStyle}}>
      <CheckboxMaterial
        checked={isChecked}
        disabled={props.disabled}
        onChange={props.onChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        style ={{color: props.disabled ? 'gray' : '#0E9AFC', marginLeft: '-11px'}}
      />
      <span style={{fontSize: '16px'}}>{props.label}</span>
    </div>
  )
}

export default Checkbox

import  React, { useEffect, useState } from 'react'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import classes from './Users.module.css'
import getColumns from '../common/UsersTable/getColumns'
import { setTableColumns } from '../../store/tablesReducer'

const UsersTable = (props) => {
  const removeUser = props.removeUser
  const setModalData = props.setModalData
  const tableColumns = getColumns(setModalData, removeUser, props.tableRows ? props.tableRows : [])
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  const tableAppearance = useSelector(state => state.tablesReducer?.usersTable)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    apiRef?.current?.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef?.current?.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('usersTable', colsData))
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        getRowId={e => e.userId}
        rows={props.tableRows ? props.tableRows : []} 
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : tableColumns} 
        rowCount={props.tableRows?.length || 0}
        pagination
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        style={{borderRadius:'15px', border:'none'}}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default UsersTable

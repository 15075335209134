import React, { useEffect } from 'react'
import {Form, Formik} from 'formik'
import { format } from 'date-fns'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import * as Yup from 'yup'
import classes from './CustomerDetails.module.css'
import { PrimaryButton, SecondaryButton, UploadButton } from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import DatePicker from '../../common/DatePicker/DatePicker'
import { Upload, message } from 'antd'

const MainDataForm = ({formRef, dataForEditing, updateCustomer, saveCustomer, isDataSent, setIsDataSent, newCustomerId}) => {
  let setFormValues = () => {}
  const today = format(new Date(), 'yyyy-MM-dd 00:00:00')
  const activeTillDefault  = moment.utc('31.12.2099', 'DD.MM.YYYY').format()
  let initialValues = {
    name: '',
    activeFrom: today,
    activeTill: activeTillDefault,
    logo: ''
  }

  useEffect(() => {
    setFormValues('name', dataForEditing?.name || '')
    setFormValues('activeFrom', dataForEditing?.activeFrom || today)
    setFormValues('activeTill', dataForEditing?.activeTill || activeTillDefault)
    setFormValues('logo', dataForEditing?.logo || '')
  }, [dataForEditing, today, activeTillDefault])

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Tenant is required'),
  })

  const onSubmit = (formikData) => {
    const activeFrom = moment.utc(formikData.activeFrom)
    const activeTill = formikData.activeTill === null ? moment.utc('2099-12-31T00:00:00') : moment.utc(formikData.activeTill)
    const formData = new FormData()
    !!formikData.logo && typeof formikData.logo !== 'string' && formData.append("logo", formikData.logo.originFileObj)
    formData.append("customer", new Blob([JSON.stringify({activeFrom, activeTill, name: formikData.name}, null, 2)], {type: 'application/json'}))
    dataForEditing 
      ? updateCustomer(dataForEditing?.custId, formData, formikData.name) 
      : saveCustomer(formData)
  }

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
  }

  return (
    <>
      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({setFieldValue, values, errors, touched, setFieldError}) => {  
          setFormValues = setFieldValue
          if (!!errors.name && !!values.name) {
            setFieldError('name' , '')
          }
          return (
            <Form className={classes.form}>
              <div className={classes.inputsArea}>
                <Input 
                  name='name'
                  label='Tenant'
                  placeholder='Tenant'
                  className='autoWidthInput'
                />
                <div className={classes.datePickerArea}>
                  <DatePicker 
                    name='activeFrom'
                    label='Active From'
                    value={values.activeFrom}
                    onChange={(value) => setFieldValue('activeFrom', moment(value).format('YYYY-MM-DDT00:00:00'))}
                  />
                  <DatePicker 
                    name='activeTill'
                    label='Active Till'
                    value={moment.utc(values.activeTill).isSame(moment(activeTillDefault)) ? null : values.activeTill}
                    onChange={(value) => setFieldValue('activeTill', moment.utc(value).format('YYYY-MM-DDT00:00:00'))}
                  />
                </div>
              </div>
              <div className={classes.logoArea}>
                <UploadButton
                  beforeUpload={beforeUpload}
                  onChange={(event) => setFieldValue("logo", event.fileList[0])}
                  name={'logo'}
                  accept={"image/png, image/jpeg"}
                  maxCount={1}
                  imgUrl={values.logo}
                  onRemove={() => setFieldValue("logo", '')}
                  btnName='Logo'
                  size='150px'
                  showUploadList={false}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
      {isDataSent && 
        <SuccessModal
          isDataSent={isDataSent}
          setIsDataSent={setIsDataSent}
          newCustomerId={newCustomerId}
          dataForEditing={dataForEditing}
        />
      }
    </>
  )
}

const SuccessModal = (props) => {
  const history = useHistory()
  const onOk = () => {
    props.setIsDataSent(false)
    history.push('/Tenants')
  }
  const onAddUsers = () => {
    props.setIsDataSent(false)
    history.push(`/Tenants/edit/${props.newCustomerId}`)
  }

  return (
    <Dialog
      open={props.isDataSent}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className={classes.successMessageModal}>
        <div className={classes.successMessage}>
          All changes have been saved
        </div>
        <div className={classes.successMessageButtons}>
          {props.dataForEditing ? (
            <PrimaryButton 
            text='OK' 
            onClick={onOk}
            style={{width: '130px', height: '40px', marginLeft: '25px'}}
            />
          ) : (
            <>
              <PrimaryButton 
                text='CLOSE' 
                onClick={onOk}
                style={{width: '130px', height: '40px', marginLeft: '25px'}}
              />
              <SecondaryButton 
                text='ADD USERS' 
                style={{width: '130px', height: '40px', marginLeft: '25px'}}
                onClick={onAddUsers}
              />
            </>  
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MainDataForm

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Form, Formik} from 'formik'
import { message, Upload } from 'antd'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import classes from './Settings.module.css'
import CustomDivider from '../common/CustomDivider/CustomDivider'
import { PrimaryButton, SecondaryButton, UploadButton } from '../common/buttons/buttons'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import { pageDefaultHeight } from '../common/sizes'
import { Input } from '../common/Input/Input'
import { updatePersonalData } from '../../store/authReducer'
import { getUserList } from '../../store/usersReducer'
import { getUserIdData } from '../../helpers/localStorage'
import generatePassword from '../../helpers/passwordGenerator'
import { getGoogleSyncLinkTC, stopSyncWithGoogleTC, stopSyncWithOfficeTC } from '../../store/synchronizationReducer'

const Settings = () => {
  let setFormValues = () => {}
  const dispatch = useDispatch()
  const history = useHistory()

  const users = useSelector(state => state.usersReducer.userList)
  const roles = useSelector(state => state.authReducer.userRoles)
  const userId = getUserIdData()
  const currentUserData = users.find(user => user.userId === Number(userId))
  const isSyncWithOffice = useSelector(state => state.synchronizationReducer.isSynchronizedWithOffice)
  const isSyncWithGoogle = useSelector(state => state.synchronizationReducer.isSynchronizedWithGoogle)
  const googleSynchronizationLink = useSelector(state => state.synchronizationReducer.googleSyncLink)
  const [isGeneratedPassword, setIsGeneratedPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataSent, setIsDataSent] = useState(false)  
  const [deleteAvatar, setDeleteAvatar] = useState(false)  

  const clientId = 'db928824-6a79-4245-bf6a-5280e6001ee8'
  const officeSynchronizationLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${window.location.protocol + "//" + window.location.host}/office-365/authorization/code&response_mode=query&scope=User.Read,Contacts.Read,User.Read.All,Contacts.ReadWrite,People.Read,People.Read.All,offline_access,openid`

  useEffect(() => {
    dispatch(getUserList())
    dispatch(getGoogleSyncLinkTC())
  }, [dispatch])

  let initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    avatar: null,
  }

  useEffect(() => {
    setFormValues('firstName', currentUserData?.firstName || '')
    setFormValues('lastName', currentUserData?.lastName || '')
    setFormValues('phoneNumber', currentUserData?.phoneNumber || '')
    setFormValues('email', currentUserData?.email || '')
    setFormValues('avatar', currentUserData?.photo || null)
  }, [currentUserData])

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    currentPassword: Yup.string()
      .when(["newPassword", "confirmPassword"], {
        is: (newPassword, confirmPassword) => !!newPassword?.length || !!confirmPassword?.length,
        then: Yup.string().required("This field is required"),
      }), 
    newPassword: Yup.string()
      .when(["currentPassword", "confirmPassword"], {
        is: (currentPassword, confirmPassword) => !!currentPassword?.length || !!confirmPassword?.length,
        then: Yup.string()
          .required("This field is required")
      }),
    confirmPassword: Yup.string()
      .when(["newPassword", "currentPassword"], {
        is: (newPassword, currentPassword) => !!newPassword?.length || !!currentPassword?.length,
        then: Yup.string()
          .required("This field is required")
          .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      }),   
  }, [["newPassword", "confirmPassword"], ["currentPassword", "confirmPassword"], ["newPassword", "currentPassword"]])

  const onSubmit = (enteredData) => {
    setIsLoading(true)
    let data
    const {firstName, lastName, phoneNumber, email, currentPassword, newPassword} = enteredData
    enteredData.currentPassword && enteredData.newPassword
      ? data = {firstName, lastName, phoneNumber, email, currentPassword, newPassword, roles}
      : data = {firstName, lastName, phoneNumber, email, currentPassword: null, newPassword: null, roles}    
    const formData = new FormData()

    if (!!enteredData.avatar && typeof enteredData.avatar !== 'string') {
      formData.append('photo', enteredData.avatar.originFileObj)
    }

    if (!!deleteAvatar) {
      formData.append('user_settings', new Blob([JSON.stringify({delete_photo: true}, null, 2)], {type: 'application/json'}))
    } else {
      formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
    }

    formData.append('user', new Blob([JSON.stringify({...data}, null, 2)], {type: 'application/json'}))
    dispatch(updatePersonalData(formData))
    .then((resp) => {
      setIsLoading(false)
      !!resp && setIsDataSent(true)
    })
  }

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
  }

  return (
    <>
      <header className={classes.header}>
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <div className={classes.pageTitle}>
          ACCOUNT SETTINGS
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({setFieldValue, values}) => {  
            setFormValues = setFieldValue
            return (
              <Form className={classes.form}>
                <div className={classes.mainData}>
                  <div className={classes.inputsArea}>
                    <Input 
                      name='firstName'
                      label='First Name'
                      placeholder='First Name'
                      className='autoWidthInput'
                    />
                    <Input 
                      name='lastName'
                      label='Last Name'
                      placeholder='Last Name'
                      className='autoWidthInput'
                    />
                    <Input 
                      name='phoneNumber'
                      label='Phone'
                      placeholder='Phone number'
                      className='autoWidthInput'
                    />
                    <Input 
                      name='email'
                      label='Email'
                      placeholder='Enter email'
                      className='autoWidthInput'
                    />
                  </div>
                  <div className={classes.avatarArea}>
                    <UploadButton
                      beforeUpload={beforeUpload}
                      onChange={(event) => {
                        setFieldValue('avatar', event.fileList[0])
                        setDeleteAvatar(false)
                      }}
                      name={'avatar'}
                      accept={"image/png, image/jpeg"}
                      maxCount={1}
                      imgUrl={values.avatar ? values.avatar : null}
                      onRemove={() => {
                        setFieldValue('avatar', '')
                        setDeleteAvatar(true)
                      }}
                      btnName='Photo'
                      size='150px'
                      showUploadList={false}
                    />
                  </div>
                </div>
                <CustomDivider
                  text='Change password'
                  textStyle={{width:'160px'}}
                  style={{margin: '0px 0px 30px 0px'}}
                />
                <div className={classes.inputsArea}>
                  <Input 
                    name='currentPassword'
                    label='Current Password'
                    placeholder='Enter your password'
                    className='autoWidthInput'
                    InputProps={{ type:'password', autoComplete: 'new-password' }}
                  />
                  <div style={{position:'relative', width:'380px'}}>
                    <div 
                      className={classes.generateBtn}
                      onClick={() => {
                        setFieldValue('newPassword', generatePassword())
                        setIsGeneratedPassword(true)
                      }}
                    >
                      Generate
                    </div>
                  </div>
                  <Input 
                    name='newPassword'
                    label='New Password'
                    placeholder='Enter new password'
                    className='autoWidthInput'
                    InputProps={isGeneratedPassword ? {} : { type:'password' }}
                    onKeyUp={() => setIsGeneratedPassword(false)}
                  />
                  <Input 
                    name='confirmPassword'
                    label='Confirm Password'
                    placeholder='Confirm new password'
                    className='autoWidthInput'
                    InputProps={{ type:'password' }}
                  />
                </div>
                <CustomDivider
                  text='Synchronization'
                  textStyle={{width:'140px'}}
                  style={{margin: '0px 0px 30px 0px'}}
                />
                <div className={classes.synchronizationWrapper}>
                  {!!isSyncWithOffice ? (
                    <SecondaryButton
                      text='Stop Synchronization with Office 365'
                      style={{width:'300px', marginBottom:'15px'}}
                      htmlType='button'
                      onClick={() => dispatch(stopSyncWithOfficeTC())}
                    />
                  ) : (
                    <a href={officeSynchronizationLink}>
                      <PrimaryButton
                        text='Set Synchronization with Office 365'
                        style={{width:'300px', marginBottom:'15px'}}
                        htmlType='button'
                      />
                    </a>
                  )}
                  {!!isSyncWithGoogle ? (
                    <SecondaryButton
                      text='Stop Synchronisation with Google'
                      style={{width:'300px', marginBottom:'15px'}}
                      onClick={() => dispatch(stopSyncWithGoogleTC())}
                      htmlType='button'
                    />
                  ) : (
                    <a href={googleSynchronizationLink}>
                      <PrimaryButton
                        text='Set Synchronisation with Google'
                        style={{width:'300px', marginBottom:'15px'}}
                        htmlType='button'
                      />
                    </a>
                  )}
                </div>
                <div className={classes.buttonsArea}>
                  <SecondaryButton
                    text='CANCEL'
                    style={{marginRight: '10px'}}
                    onClick={() => history.push('/StakeholderMap')}
                  />
                  <PrimaryButton
                    text='SAVE'
                    htmlType='submit'
                    loading={isLoading}
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {!!isDataSent && <SuccessModal setIsDataSent={setIsDataSent} isDataSent={isDataSent}/>}
    </>
  )
}

const SuccessModal = (props) => {
  const onOk = () => {
    props.setIsDataSent(false)
    // window.location.reload()
  }

  return (
    <Dialog
      open={props.isDataSent}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className={classes.successMessageModal}>
        <div className={classes.successMessage}>
          All changes have been saved
        </div>
        <div className={classes.successMessageButtons}>
          <PrimaryButton 
            text='OK' 
            onClick={onOk}
            style={{width: '130px', height: '40px', marginLeft: '25px'}}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default Settings

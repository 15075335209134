import React, { useEffect, useState } from 'react'
import { Button, Upload } from 'antd'
import { getBase64 } from '../../../helpers/filesHepler'
import classes from './buttons.module.css'
import {ReactComponent as DeleteIconWhite} from './../../../img/icons/deleteIconWhite.svg'
import {ReactComponent as EditIconWhite} from './../../../img/icons/editIconWhite.svg'

export const PrimaryButton = (props) => {
  return (
    <Button 
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        ...props.style, 
        backgroundColor: '#0E9AFC', 
        color: 'white', 
        borderColor: 'none',
        borderRadius: '10px',
        fontWeight: '600'
      }}
      type='primary'
      htmlType={props.htmlType || 'button'}
      icon={props.icon}
      loading={props.loading}
    >
      {props.text}
    </Button>
  )
}

export const SecondaryButton = (props) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        backgroundColor: 'transparent', 
        borderRadius: '10px', 
        border: '2px solid #0E9AFC',
        fontWeight: '600', 
        ...props.style, 
      }}
      id={props.id}
      loading={props.loading}
    >
      <span id='secondaryBtnText' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        {!!props.icon && <props.icon style={{marginRight: '7px'}}/>}
        {props.text}
      </span>
    </Button>
  )
}

export const UploadButton = ({
  beforeUpload,
  onChange,
  onRemove,
  name,
  accept,
  maxCount,
  imgUrl,
  error,
  btnName,
  size,
  showUploadList
}) => {
  const dummyRequest = ({_, onSuccess}) => setTimeout(() => onSuccess("ok"), 0)
  const [imagePreview, setImagePreview] = useState(null)



  useEffect(() => {
    const setImageData = async() => {
      if (!!imgUrl) {
        let imageUrl
        typeof imgUrl !== 'string' && !!imgUrl 
          ? imageUrl = await getBase64(imgUrl.originFileObj) 
          : imageUrl = `data:image/png;base64,${imgUrl}`
        setImagePreview(imageUrl)
      } else {
        setImagePreview(null)
      }
    }
    setImageData()
  }, [imgUrl])

  return (
    <>
      <Upload 
        beforeUpload={beforeUpload}
        onChange={onChange}
        name={name}
        customRequest={dummyRequest}
        accept={accept}
        maxCount={maxCount}
        listType="text"
        onRemove={() => {onRemove(); setImagePreview(null)}}
        showUploadList={showUploadList}
      >
        {imagePreview ? (
          <div className={classes.uploadPreview}>
            <img 
              src={imagePreview}
              style={{
                width: size ? size : '104px',
                height: size ? size : '104px',
                border: '1px dashed #D9D9D9',
                borderRadius: '5px',
                objectFit: 'scale-down'
              }}
              alt=''
            />
            <div className={classes.previewTools}>
              <EditIconWhite style={{marginRight: '25px'}} />
              <DeleteIconWhite
                onClick={(e) => {
                  e.stopPropagation()
                  onRemove()
                  setImagePreview(null)
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              alignItems: 'center',
              background: '#FAFAFA',
              border: `1px dashed ${error ? 'red' : '#D9D9D9'}`,
              borderRadius: '5px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.45)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: size ? size : '104px',
              width: size ? size : '104px',
            }}
          >
            <div style={{fontSize:'24px'}}>+</div>
            <div>Upload</div>
            <div>{btnName}</div>
          </div>
        )}
      </Upload>
      {error && 
        <div 
          style={{
            color: 'red',
            marginTop: '5px',
            marginLeft: '5px',
            fontSize: '12px'
          }}
        >
          {error}
        </div>
      }
    </>
  )
}

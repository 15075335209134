import React from 'react'
import {version} from '../../package.json'

const VersionPage = () => {
  const wrapperStyle={
    alignItems:'center',  
    display:'flex',
    fontSize:'24px', 
    height: '100%',
    justifyContent:'center',
    width: '100%', 
  }

  const versionInfoStyle = {
    backgroundColor: '#c8c7f4',
    borderRadius:'15px',
    padding: '40px',
  }

  return (
    <div style={wrapperStyle}>
      <div style={versionInfoStyle}>
      Current project version: {version}
      </div>
    </div>
  )
}

export default VersionPage

import { DataGridPro } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Modal } from 'antd'
import { getCurrentUserRoles } from '../../helpers/localStorage'
import Search from '../common/Search/Search'
import { pageDefaultHeight, tableWidth } from '../common/sizes'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import classes from './Templates.module.css'
import {ReactComponent as EditIcon} from './../../img/icons/editIcon.svg'
import emailTemplateImg from './../../img/emailTemplateImg.png'
import { PrimaryButton, SecondaryButton } from '../common/buttons/buttons'
import { getTemplateByCodeTC, getTemplatesTC, updateTemplateTC } from '../../store/emailTemplatesReducer'
import TextArea from 'antd/lib/input/TextArea'
import SuccessModal from '../common/SuccessModal/SuccessModal'

const Templates = () => {
  const dispatch = useDispatch()
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn)
  const templatesData = useSelector(state => state.emailTemplatesReducer.templates)
  const currentUserRoles = getCurrentUserRoles()
  const isAdmin = currentUserRoles == null ? false : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin")

  const [tableRows, setTableRows] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onSearch = (value) => {
    const searchResults = templatesData?.filter(item => item.title.toLowerCase().includes(value.toLowerCase()))
    if (!value) {
      setTableRows(templatesData)
    }
    setTableRows(searchResults)
  }

  const onEdit = (template) => {
    dispatch(getTemplateByCodeTC(template.code))
    setIsModalOpen(true)
  }

  useEffect(() => {
    dispatch(getTemplatesTC())
  }, [dispatch])

  useEffect(() => {
    setTableRows(templatesData)
  }, [templatesData])

  if(!isAdmin && isSignedIn) {
    return <Redirect to='/StakeholderMap' />
  }

  const columns = [
    { field: 'event',
      headerName: 'Template',
      width: tableWidth / 2,
    },
    { field: 'actions',
      headerName: 'Actions',
      width: tableWidth / 2,
      renderCell: (params) => {
        return (
          <EditIcon 
            className={classes.tableIcon}
            onClick={() => onEdit(params.row)}
            style={{marginRight: '15px'}}
          />
        )
      }
    },
  ]

  return (
    <>
      <header className={classes.header}>
        <Search onChange={event => onSearch(event.target.value)} />
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <div className={classes.titleArea} >
          <h1 className={classes.pageTitle}>
            TEMPLATES LIST
          </h1>
        </div>
        <div className={classes.tableWrapper}>
          <DataGridPro
            getRowId={e => e.code}
            rows={tableRows} 
            columns={columns} 
            rowCount={templatesData?.length || 0}
            style={{borderRadius:'15px', border:'none'}}
          />
        </div>
        {!!isModalOpen && 
          <TemplateModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        } 
      </div>
    </>
  )
}

const TemplateModal = ({isModalOpen, setIsModalOpen}) => {
  const data = useSelector(state => state.emailTemplatesReducer.selectedTemplate)
  const dispatch = useDispatch()
  const [textLines, setTextLines] = useState([])
  const [isSaved, setIsSaved] = useState(false)

  useEffect(() => {
    if(!!isModalOpen && !!data) {
      let example = document.getElementById('mailExample');
      example.innerHTML = data.htmlValue;
      let selected = example.getElementsByClassName('textLine');
      setTextLines(Array.from(selected))
    }
  }, [isModalOpen, data])

  const saveChanges = () => {
    const editedExample = document.getElementById('mailExample')
    dispatch(updateTemplateTC({...data, htmlValue: editedExample.innerHTML}))  
      .then(() => setIsSaved(true))
  }

  return (
    <Modal
      centered
      closable={false}   
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      style={{padding: '10px', width: '100%'}}
      visible={true} 
      width={1300}
    >
      <div className={classes.modal}>
        <img src={emailTemplateImg} alt='' />
        <div className={classes.modalTitle}>
          {!!data && data?.event}
        </div>
        <div style={{display:'grid', gridTemplateColumns:'40% 60%', width: '100%'}}>
          <div>
            {textLines.map((i, index) => (
              <TextArea
                key={index}
                rows={2}
                value={`${i.innerHTML}`}
                onChange={(e) => { 
                  setTextLines(textLines.map(line => {
                    if (line === i) {
                      line.innerHTML = e.target.value
                      return line
                    } else {
                      return line
                    }
                  }))
                }}
                style={{marginBottom: '10px'}}
              />
            ))}
          </div>
          <div id='mailExample'/>
        </div>
        
        <div className={classes.buttonsArea}>
          <SecondaryButton
            onClick={() => setIsModalOpen(false)}
            style={{width:'100px', height: 'auto'}}
            text='Cancel' 
          />
          <PrimaryButton 
            className={classes.primaryButton}    
            onClick={saveChanges}
            style={{width:'100px', height: 'auto', marginLeft: '15px'}}
            text='SAVE' 
          />
        </div>
      </div>
      {!!isSaved &&
        <SuccessModal
          onOk={() => setIsModalOpen(false)}
          isOpen={isSaved}
          text='All changes have been saved'
        />
      }
    </Modal>
  )
}

export default Templates

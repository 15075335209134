import React from 'react'
import { Input } from 'antd'
import {ReactComponent as SearchIcon} from './../../../img/icons/searchIcon.svg'

const Search = (props) => {
  return (
    <Input 
      onChange={props.onChange} 
      placeholder={props.placeholder}
      prefix={<SearchIcon />} 
      style={{ width: 290, height:'56px', borderRadius: '15px', ...props.style}} 
    />
  )
}

export default Search

import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'

const initialState = {
  customers: [],
  customer: {},
  customerUsers: []
}

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERS':
      return {...state, customers: action.customers} 
    case 'SET_CUSTOMER':
      return {...state, customer: action.customer} 
    case 'ADD_CUSTOMER':
      return {...state, customers: [...state.customers, action.customer]} 
    case 'REMOVE_CUSTOMER':
      return {...state, customers: state.customers.filter(cust => cust.custId !== action.id)} 
    case 'EDIT_CUSTOMER':
      return {...state, customers: state.customers.map(customer => {
        if (customer.custId === action.customer.custId) {
          return action.customer
        } else {
          return customer
        }
      })} 
    case 'SET_CUSTOMER_USERS':
      return {...state, customerUsers: action.users} 
    case 'ADD_CUSTOMER_USER':
      return {...state, customerUsers: [...state.customerUsers, action.user]} 
    case 'REMOVE_CUSTOMER_USER':
      return {...state, customerUsers: state.customerUsers.filter(user => user.userId !== action.userId)} 
    case 'EDIT_CUSTOMER_USER':
      return {...state, customerUsers: state.customerUsers.map(user => {
        if (user.userId === action.user.userId) {
          return action.user
        } else {
          return user
        }
      })} 
    case 'DEACTIVATE_CUSTOMER_USER':
      return {...state, customerUsers: state.customerUsers.map(user => {
        if (user.userId === action.userId) {
          return {...user, isActive: false}
        } else {
          return user
        }
      })} 
    default: return state
  } 
}

export const setCustomers = (customers) => ({type: 'SET_CUSTOMERS', customers})
export const setCustomer = (customer) => ({type: 'SET_CUSTOMER', customer})
export const addCustomer = (customer) => ({type: 'ADD_CUSTOMER', customer})
export const editCustomer = (customer) => ({type: 'EDIT_CUSTOMER', customer})
export const removeCustomer = (id) => ({type: 'REMOVE_CUSTOMER', id})

export const setCustomerUsers = (users) => ({type: 'SET_CUSTOMER_USERS', users})
export const addCustomerUser = (user) => ({type: 'ADD_CUSTOMER_USER', user})
export const editCustomerUser = (user) => ({type: 'EDIT_CUSTOMER_USER', user})
export const removeCustomerUser = (userId) => ({type: 'REMOVE_CUSTOMER_USER', userId})
export const deactivateCustomerUserById = (userId) => ({type: 'DEACTIVATE_CUSTOMER_USER', userId})

export const getCustomers = (isActive = 'ALL') => (dispatch) => {
    return axios.get(`/system-admin/customers?active_status=${isActive}`)
      .then(response => {
        response.status === 200 
        ? dispatch(setCustomers(response.data.customers))
        : dispatch(handleError(response.data))
      })
    .catch(error => dispatch(handleError(error)))
}

export const getCustomer = (custId) => (dispatch) => {
  return axios.get(`/system-admin/customers/${custId}`)
    .then(response => {
      response.status === 200 
      ? dispatch(setCustomer(response.data.customer))
      : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export const createCustomer = (customer) => (dispatch) => {
  return axios.post(`/system-admin/customers`, customer)
    .then(response => {
      if (response.status === 201) {
        dispatch(addCustomer(response.data.customer))
        return response.data.customer
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export const updateCustomer = (custId, updatedCustomer) => (dispatch) => {
  return axios.put(`/system-admin/customers/${custId}`, updatedCustomer)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(editCustomer(response.data.customer))
        return response.data.customer
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export const deleteCustomer = (custId) => (dispatch) => {
  return axios.delete(`/system-admin/customers/${custId}/deactivate`)
    .then(response => {
      response.status === 200 
      ? dispatch(editCustomer(response.data.customer))
      : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export const getCustomerUsers = (custId) => (dispatch) => {
  return axios.get(`/system-admin/customers/${custId}/users`)
    .then(response => {
      response.status === 200 
      ? dispatch(setCustomerUsers(response.data.users))
      : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export const validateCustomerUser = (user, userId) => (dispatch) => {
  return axios.post(`/system-admin/customers/users/validate${!!userId ? `?user_id=${userId}` : ''}`, user)
    .then(response => {
      if (response.status === 201) {
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export const createCustomerUser = (custId, user) => (dispatch) => {
  const formData = new FormData()
  formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
  formData.append('user', new Blob([JSON.stringify({...user}, null, 2)], {type: 'application/json'}))
  return axios.post(`/system-admin/customers/${custId}/users`, formData)
    .then(response => {
      if (response.status === 201) {
        dispatch(addCustomerUser(response.data.user))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export const updateCustomerUser = (custId, updatedUser) => (dispatch) => {
  const formData = new FormData()
  formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
  formData.append('user', new Blob([JSON.stringify({...updatedUser}, null, 2)], {type: 'application/json'}))
  return axios.put(`/system-admin/customer/${custId}/users/${updatedUser.userId}`, formData)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(editCustomerUser(response.data.user))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export const deleteCustomerUser = (custId, userId) => (dispatch) => {
  return axios.delete(`/system-admin/customers/${custId}/users/${userId}`)
    .then(response => {
      response.status === 200 
      ? dispatch(removeCustomerUser(userId))
      : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export const deactivateCustomerUser = (custId, userId, delete_stakeholder = false) => (dispatch) => {
  return axios.delete(`/system-admin/customers/${custId}/users/${userId}/deactivate?delete_stakeholder=${delete_stakeholder}`)
    .then(response => {
      response.status === 200 
      ? dispatch(deactivateCustomerUserById(userId))
      : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export default customersReducer

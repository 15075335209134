import React, { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { getOrganizationalChartData } from '../../../store/chartsReducer'
import { getOrganizations } from '../../../store/organizationsReducer'
import OrganisationalChart from './OrganisationalChart'

const OrganisationalChartContainer = (props) => {
  const [companyOptions, setCompanyOptions] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(companyOptions[0]?.value) 
  const [selectedView, setSelectedView] = useState('nameView')
  const [chartWidth, setChartWidth] = useState(0)

  useEffect(() => {
    props.getOrganizations(true)
  }, [props])

  useEffect(() => {
    const options = props.organizations.map(org => ({value: org.orgId, name: org.name, id: org.orgId}))
    setCompanyOptions(options)
    setSelectedCompany(options[0]?.value)
  }, [props.organizations])

  useEffect(() => {
    if (selectedCompany) {
      props.getOrganizationalChartData(selectedCompany)
    }
  }, [selectedCompany, props])

  const viewTypes = [{name: 'Name view', value:'nameView', id: 1}]

  const data = {
    id: 'n1',
    name: 'John',
    title: 'General manager',
    children: [
      { id: 'n2', name: 'Ann', title: 'Department manager' },
      { id: 'n3',
        name: 'Josh',
        title: 'Department manager',
        children: [
          { id: 'n4', name: 'Nick', title: 'Senior engineer' },
          { id: 'n5',
            name: 'Ray',
            title: 'Senior engineer',
            children: [
              { id: 'n6', name: 'Jackson', title: 'Engineer' },
              { id: 'n7', name: 'Mike', title: 'Engineer' }
            ]
          },
          { id: 'n8', name: 'Eleanor', title: 'Senior engineer' }
        ]
      },
      { id: 'n10',
        name: 'Anna',
        title: 'Department manager',
        children: [{ id: 'n11', name: 'Nicolas', title: 'Senior engineer' }]
      }
    ],
  }

  if(process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    return <Redirect to='/StakeholderMap'/>
  }

  return (
    <OrganisationalChart 
      data={data}
      chartWidth={chartWidth}
      selectedCompany={selectedCompany}
      selectCompanyOptions={companyOptions}
      setSelectedCompany={setSelectedCompany}
      selectedView={selectedView}
      setSelectedView={setSelectedView}
      setChartWidth={setChartWidth}
      viewTypes={viewTypes}
    />
  )
}

const mapStateToProps = (state) => ({
  organizations: state.organizationsReducer.organizations,
  organizationalChart: state.chartsReducer.organizationalChart,
})

export default connect(mapStateToProps, {getOrganizations, getOrganizationalChartData})(OrganisationalChartContainer)

import React, { useState } from 'react'
import { Drawer, Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classes from './NavBar.module.css'
import FeedbackModal from './FeedbackModal'
import {SecondaryButton} from './../common/buttons/buttons'
import {navBarHeight} from './../common/sizes'
import {ReactComponent as ProjectsIcon} from './../../img/navBar/projectsIcon.svg'
import {ReactComponent as ContactsIcon} from './../../img/navBar/contactsIcon.svg'
import {ReactComponent as MatrixIcon} from './../../img/navBar/matrixIcon.svg'
import {ReactComponent as ChartsIcon} from './../../img/navBar/chartsIcon.svg'
import {ReactComponent as StakeholderMapIcon} from './../../img/navBar/stakeholderMapIcon.svg'
import {ReactComponent as UsersIcon} from './../../img/navBar/usersIcon.svg'
import {ReactComponent as OrganizationsIcon} from './../../img/navBar/organizationsIcon.svg'
import {ReactComponent as ProjectsIconSelected} from './../../img/navBar/filled/projectsIconFilled.svg'
import {ReactComponent as ContactsIconSelected} from './../../img/navBar/filled/contactsIconFilled.svg'
import {ReactComponent as MatrixIconSelected} from './../../img/navBar/filled/matrixIconFilled.svg'
import {ReactComponent as ChartsIconSelected} from './../../img/navBar/filled/chartsIconFilled.svg'
import {ReactComponent as StakeholderMapIconSelected} from './../../img/navBar/filled/stakeholderMapIconFilled.svg'
import {ReactComponent as UsersIconSelected} from './../../img/navBar/filled/usersIconFilled.svg'
import {ReactComponent as OrganizationsIconSelected} from './../../img/navBar/filled/organizationsIconFilled.svg'
import {ReactComponent as CustomersIcon} from './../../img/navBar/customersIcon.svg'
import {ReactComponent as CustomersIconSelected} from './../../img/navBar/filled/customersIconFilled.svg'
import {ReactComponent as DefaultLogo} from './../../img/defaultLogo.svg'
import {ReactComponent as StakeholderEngagement} from './../../img/navBar/stakeholderEngagement.svg'
import {ReactComponent as StakeholderEngagementSelected} from './../../img/navBar/filled/stakeholderEngagement.svg'
import {ReactComponent as RecordEngagement} from './../../img/navBar/recordEngagement.svg'
import {ReactComponent as RecordEngagementSelected} from './../../img/navBar/filled/recordEngagement.svg'
import {ReactComponent as ViewEngagement} from './../../img/navBar/viewEngagement.svg'
import {ReactComponent as ViewEngagementSelected} from './../../img/navBar/filled/viewEngagement.svg'
import {ReactComponent as SetEngagement} from './../../img/navBar/setEngagement.svg'
import {ReactComponent as SetEngagementSelected} from './../../img/navBar/filled/setEngagement.svg'
import {ReactComponent as EngagementSummary} from './../../img/navBar/engagementSummary.svg'
import {ReactComponent as EngagementSummarySelected} from './../../img/navBar/filled/engagementSummary.svg'
import menuUnfold from './../../img/navBar/menuUnfold.svg'
import menuFold from './../../img/navBar/menuFold.svg'
import {ReactComponent as CompanyLogoSmall} from './../../img/companyLogoSmall.svg'
import {ReactComponent as SettingsIcon} from './../../img/icons/settings.svg'
import {ReactComponent as TemplatesIconFilled} from './../../img/navBar/filled/templatesIconFilled.svg'
import {ReactComponent as TemplatesIcon} from './../../img/navBar/templatesIcon.svg'

const NavBar = (props) => {
  const checkIsMenuItemActive = (menuItemKeys) => menuItemKeys.some(key => key === props.activeItem)
  const getDefaultOpenKeys = () => {
    const engagementSubItems = ['/RecordEngagement/1', '/RecordEngagement/2', '/UpcomingEngagements', '/SetEngagement', '/ViewEngagement']
    const isSubMenuItemOpened = engagementSubItems.some(item => item === props.activeItem)
    return isSubMenuItemOpened ? ['engagement'] : []
  }
  const isTabletScreen = useMediaQuery({ query: '(min-width: 767px) and (max-width: 1366px)' })
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  if(isTabletScreen) {
    return <TabletMenu {...props} />
  }

  if (isMobileScreen) {
    return <MobileNavBar {...props} />
  }

  return (
    <div className={`${classes.wrapper} ${props.className}`} style={{height:`${navBarHeight}px`}}>
      {props.logo
        ? <img src={`data:image/jpeg;base64,${props.logo}`} className={classes.companyLogo} id='logo' alt=''/>
        : <DefaultLogo className={classes.companyLogo} id='logo'/>
      }
      <Menu
        className={classes.menu}
        defaultOpenKeys={getDefaultOpenKeys()}
        defaultSelectedKeys={[props.activeItem]}
        selectedKeys={
          props.activeItem === '/RecordEngagement/1' || props.activeItem === '/RecordEngagement/2' 
            ? '/RecordEngagement'
            : [props.activeItem]
        }
        mode='inline'
        onClick={(e) => props.setActiveItem(e.key)}
      >
        {props.isSystemAdmin ? (
          <>
            <Menu.Item key='/Tenants' icon={checkIsMenuItemActive(['/Tenants']) ? <CustomersIconSelected style={{width:'24px'}}/> : <CustomersIcon />}>
              <NavLink className={classes.link} to='/Tenants'>
                TENANTS
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Templates' icon={checkIsMenuItemActive(['/Templates']) ? <TemplatesIconFilled /> : <TemplatesIcon />}>
              <NavLink className={classes.link} to='/Templates'>
                TEMPLATES
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Users' icon={checkIsMenuItemActive(['/Users']) ? <UsersIconSelected /> : <UsersIcon />}>
              <NavLink className={classes.link} to='/Users'>
                USERS
              </NavLink>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key='/StakeholderMap' icon={checkIsMenuItemActive(['/StakeholderMap']) ? <StakeholderMapIconSelected/> : <StakeholderMapIcon />}>
              <NavLink className={classes.link} to='/StakeholderMap'>
                STAKEHOLDER MAP
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/SupportChart' icon={checkIsMenuItemActive(['/SupportChart']) ? <ChartsIconSelected /> : <ChartsIcon />}>
              <NavLink className={classes.link} to='/SupportChart'>
                SUPPORT CHART
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key='/Charts/organisational' icon={checkIsMenuItemActive(['/Charts/organisational']) ? <ChartsIconSelected /> : <ChartsIcon />}>
              <NavLink className={classes.link} to='/Charts/organisational'>
                ORGANISATIONAL CHART
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key='/InfluenceMatrix' icon={checkIsMenuItemActive(['/InfluenceMatrix']) ? <MatrixIconSelected /> : <MatrixIcon />}>
              <NavLink className={classes.link} to='/InfluenceMatrix'>
                INFLUENCE MATRIX
              </NavLink>
            </Menu.Item>
            <Menu.SubMenu
              key='engagement'
              icon={
                checkIsMenuItemActive(['/UpcomingEngagements', '/SetEngagement', '/RecordEngagement/1', '/RecordEngagement/2', '/ViewEngagement']) 
                  ? <StakeholderEngagementSelected style={{width:'24px'}}/>
                  : <StakeholderEngagement style={{width:'24px'}}/>
              }
              title='ENGAGEMENT'
            >
              <Menu.Item key='/UpcomingEngagements' icon={checkIsMenuItemActive(['/UpcomingEngagements']) ? <EngagementSummarySelected style={{width:'20px', marginLeft: '-15px'}}/> : <EngagementSummary style={{width:'20px', marginLeft: '-15px'}}/>}>
                  <NavLink className={classes.link} to='/UpcomingEngagements'>
                    UPCOMING ENGAGEMENTS
                  </NavLink>
                </Menu.Item>
                <Menu.Item key='/SetEngagement' icon={checkIsMenuItemActive(['/SetEngagement']) ? <SetEngagementSelected style={{width:'20px', marginLeft: '-15px'}}/> : <SetEngagement style={{width:'20px', marginLeft: '-15px'}}/>}>
                  <NavLink className={classes.link} to='/SetEngagement'>
                    SET ENGAGEMENT
                  </NavLink>
                </Menu.Item>
                <Menu.Item key='/RecordEngagement' icon={checkIsMenuItemActive(['/RecordEngagement/1', '/RecordEngagement/2']) ? <RecordEngagementSelected style={{width:'20px', marginLeft: '-15px'}}/> : <RecordEngagement style={{width:'20px', marginLeft: '-15px'}}/>}>
                  <NavLink className={classes.link} to='/RecordEngagement/1'>
                    RECORD ENGAGEMENT
                  </NavLink>
                </Menu.Item>
                <Menu.Item key='/ViewEngagement' icon={checkIsMenuItemActive(['/ViewEngagement']) ? <ViewEngagementSelected style={{width:'20px', marginLeft: '-15px'}}/> : <ViewEngagement style={{width:'20px', marginLeft: '-15px'}}/>}>
                  <NavLink className={classes.link} to='/ViewEngagement'>
                    VIEW ENGAGEMENT
                  </NavLink>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key='/Projects' icon={checkIsMenuItemActive(['/Projects']) ? <ProjectsIconSelected /> : <ProjectsIcon />}>
              <NavLink className={classes.link} to='/Projects'>
                PROJECTS
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Contacts' icon={checkIsMenuItemActive(['/Contacts']) ? <ContactsIconSelected /> : <ContactsIcon />}>
              <NavLink className={classes.link} to='/Contacts'>
                CONTACTS
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Organisations' icon={checkIsMenuItemActive(['/Organisations']) ? <OrganizationsIconSelected /> : <OrganizationsIcon/>}>
              <NavLink className={classes.link} to='/Organisations'>
                ORGANISATIONS
              </NavLink>
            </Menu.Item>
          </>
        )}
      </Menu>
      <SecondaryButton 
        text='SEND FEEDBACK' 
        style={{marginBottom:'20px'}} 
        onClick={() => props.setIsFeedbackModalOpen(true)}
      />
      {props.isFeedbackModalOpen && 
        <FeedbackModal 
          visible={props.isFeedbackModalOpen}
          sendFeedbackMessage={props.sendFeedbackMessage}
          closeFeedbackModal={props.closeFeedbackModal}
          isFeedbackSent={props.isFeedbackSent}
        />
      }
      {/* {props.infoAlert && 
        <InfoAlert 
          alertText={props.infoAlert} 
          onClose={() => props.setInfoAlert(null)}
        />
      } */}
    </div>
  )
}

const TabletMenu = (props) => {
  const checkIsMenuItemActive = (menuItemKeys) => menuItemKeys.some(key => key === props.activeItem)
  const [collapsed, setCollapsed] = useState(true)

  const selectMenuItem = (key) => {
    props.setActiveItem(key)
    setCollapsed(true)
  }

  const menu = (
    <Menu
      mode='inline'
      inlineCollapsed={collapsed}
      defaultSelectedKeys={[props.activeItem]}
      onClick={(e) => selectMenuItem(e.key)}
      style={{marginTop: collapsed ? '0px' : '45px'}}
    >
      {props.isSystemAdmin ? (
          <>
            <Menu.Item key='/Tenants' icon={checkIsMenuItemActive(['/Tenants']) ? <CustomersIconSelected style={{width:'24px'}}/> : <CustomersIcon />}>
              <NavLink className={classes.link} to='/Tenants'>
                TENANTS
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Templates' icon={checkIsMenuItemActive(['/Templates']) ? <TemplatesIconFilled /> : <TemplatesIcon />}>
              <NavLink className={classes.link} to='/Templates'>
                TEMPLATES
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/Users' icon={checkIsMenuItemActive(['/Users']) ? <UsersIconSelected /> : <UsersIcon />}>
              <NavLink className={classes.link} to='/Users'>
                USERS
              </NavLink>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item title='' key='/StakeholderMap' icon={checkIsMenuItemActive(['/StakeholderMap']) ? <StakeholderMapIconSelected /> : <StakeholderMapIcon />}>
              <NavLink className={classes.link} to='/StakeholderMap'>
                STAKEHOLDER MAP
              </NavLink>
            </Menu.Item>
            <Menu.Item title='' key='/SupportChart' icon={checkIsMenuItemActive(['/SupportChart']) ? <ChartsIconSelected /> : <ChartsIcon />}>
              <NavLink className={classes.link} to='/SupportChart'>
                SUPPORT CHART
              </NavLink>
            </Menu.Item>
            <Menu.Item title='' key='/InfluenceMatrix' icon={checkIsMenuItemActive(['/InfluenceMatrix']) ? <MatrixIconSelected /> : <MatrixIcon />}>
              <NavLink className={classes.link} to='/InfluenceMatrix'>
                INFLUENCE MATRIX
              </NavLink>
            </Menu.Item>
          </>
        )
      }
    </Menu>
  )

  return (
    <div className={`${classes.tabletMenuWrapper} ${props.className}`}>
      <CompanyLogoSmall style={{marginBottom: '10px'}}/>
      {collapsed
        ? (
          <>
            <img src={menuUnfold} onClick={() => setCollapsed(!collapsed)} alt=''/>
            {menu}
          </>
        ) : (
          <Drawer closable={false} placement='left' visible={!collapsed} bodyStyle={{overflow:'visible'}}>
            {props.logo
              ? <img src={`data:image/jpeg;base64,${props.logo}`} className={classes.companyLogo} id='logo' alt=''/>
              : <DefaultLogo className={classes.companyLogo} id='logo'/>
            }
            <img
              src={menuFold}
              onClick={() => setCollapsed(!collapsed)}
              style={{position:'absolute', right: '-15px'}}
              alt=''
            />
            {menu}
          </Drawer> 
        )
      }
    </div>
  )
}

export const MobileNavBar = (props) => {
  const checkIsMenuItemActive = (menuItemKeys) => menuItemKeys.some(key => key === props.activeItem)
  const [collapsed, setCollapsed] = useState(true)

  const selectMenuItem = (key) => {
    props.setActiveItem(key)
    setCollapsed(true)
  }

  const menu = (
    <Menu
      mode='inline'
      inlineCollapsed={collapsed}
      defaultSelectedKeys={[props.activeItem]}
      onClick={(e) => selectMenuItem(e.key)}
      style={{marginTop: collapsed ? '0px' : '45px'}}
    >
      {props.isSystemAdmin ? (
        <>
          <Menu.Item key='/Tenants' icon={checkIsMenuItemActive(['/Tenants']) ? <CustomersIconSelected style={{width:'24px'}}/> : <CustomersIcon />}>
            <NavLink className={classes.link} to='/Tenants'>
              TENANTS
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/Templates' icon={checkIsMenuItemActive(['/Templates']) ? <TemplatesIconFilled /> : <TemplatesIcon />}>
            <NavLink className={classes.link} to='/Templates'>
              TEMPLATES
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/Users' icon={checkIsMenuItemActive(['/Users']) ? <UsersIconSelected /> : <UsersIcon />}>
            <NavLink className={classes.link} to='/Users'>
              USERS
            </NavLink>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item title='' key='/StakeholderMap' icon={checkIsMenuItemActive(['/StakeholderMap']) ? <StakeholderMapIconSelected /> : <StakeholderMapIcon />}>
            <NavLink className={classes.link} to='/StakeholderMap'>
              STAKEHOLDER MAP
            </NavLink>
          </Menu.Item>
          <Menu.Item title='' key='/SupportChart' icon={checkIsMenuItemActive(['/SupportChart']) ? <ChartsIconSelected /> : <ChartsIcon />}>
            <NavLink className={classes.link} to='/SupportChart'>
              SUPPORT CHART
            </NavLink>
          </Menu.Item>
          <Menu.Item title='' key='/InfluenceMatrix' icon={checkIsMenuItemActive(['/InfluenceMatrix']) ? <MatrixIconSelected /> : <MatrixIcon />}>
            <NavLink className={classes.link} to='/InfluenceMatrix'>
              INFLUENCE MATRIX
            </NavLink>
          </Menu.Item>
        </>
      )}
    </Menu>
  )

  return (
    <div className={classes.mobileMenuWrapper}>
      {collapsed
        ? (
          <img src={menuUnfold} onClick={() => setCollapsed(!collapsed)} alt=''/>
        ) : (
          <Drawer closable={false} placement='left' visible={!collapsed} bodyStyle={{overflow:'visible'}}>
            {props.logo
              ? <img src={`data:image/jpeg;base64,${props.logo}`} className={classes.companyLogo} id='logo' alt=''/>
              : <DefaultLogo className={classes.companyLogo} id='logo'/>
            }
            <img
              src={menuFold}
              onClick={() => setCollapsed(!collapsed)}
              style={{position:'absolute', right: '-15px'}}
              alt=''
            />
            {menu}
          </Drawer> 
        )
      }
      {props.logo
        ? <img src={`data:image/jpeg;base64,${props.logo}`} className={classes.companyLogo} id='logo' alt=''/>
        : <DefaultLogo className={classes.companyLogo} id='logo'/>
      }
      {props.setIsToolsVisible &&
        <SettingsIcon
          onClick={() => props.setIsToolsVisible(true)}
          className={classes.toolsAreaMobile}
        />
      }
    </div>
  )
}

export default NavBar

import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './Customers.module.css'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import {PrimaryButton} from './../common/buttons/buttons'
import {pageDefaultHeight} from './../common/sizes'
import CustomersTable from './CustomersTable'
import Select from '../common/Select/Select'

const Customers = (props) => {
  return (
    <>
      <header className={classes.header}>
        <Search onChange={(e) => props.setSearchQuery(e.target.value)} />
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height:`${pageDefaultHeight}px`}}>
        <div className={classes.titleArea}>
          <h1 className={classes.pageTitle}>TENANTS LIST</h1>
          <div>
            <Select
              options={props.periodOptions}
              onSelect={(value) => props.setSelectedIsActive(value)}
              style={{width: '200px', marginRight: '15px'}}
              value={props.selectedIsActive}
              className='smallSelect'
            />
            <NavLink to='/Tenants/add-tenant'>
              <PrimaryButton text='ADD TENANT'/>
            </NavLink>
          </div>
        </div>
        <CustomersTable
          customers={props.searchResults ? props.searchResults : props.customers}
          deleteCustomer={props.deleteCustomer}
          selectedIsActive={props.selectedIsActive}
        />
      </div>
    </>
  )
}

export default Customers

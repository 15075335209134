import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import classes from './CustomerDetails.module.css'
import {pageDefaultHeight} from './../../common/sizes'
import UserInfoArea from './../../common/UserInfoArea/UserInfoArea'
import {ReactComponent as GoBackIcon} from './../../../img/icons/goBackIcon.svg'
import MainDataForm from './MainDataForm'
import UsersTable from './UsersTable'
import { Link } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import CustomDivider from './../../common/CustomDivider/CustomDivider'

const CustomerDetails = (props) => {
  const history = useHistory()
  const {id} = useParams()
  const formRef = useRef()

  return (
    <>
      <header className={classes.header}>
        <div className={classes.pageTitle}>
          <GoBackIcon 
            onClick={() => history.push(`/Tenants`)}
            style={{cursor: 'pointer', marginRight: '10px'}}
          /> 
          {props.dataForEditing ? props.dataForEditing.name?.toUpperCase() : 'NEW TENANT'}
        </div>
        <UserInfoArea />
      </header>
      <div style={{height: pageDefaultHeight}} className={classes.tabsWrapper}>
        <MainDataForm
          dataForEditing={props.dataForEditing}
          isDataSent={props.isDataSent}
          setIsDataSent={props.setIsDataSent}
          newCustomerId={props.newCustomerId}
          saveCustomer={props.saveCustomer}
          updateCustomer={props.updateCustomer}
          formRef={formRef}
        />
        {!!id &&
          <>
            <CustomDivider 
              text='Tenant Users'
              textStyle={{
                width:'130px',
                fontSize:'14px',
                fontWeight:600,
                color:'#0E9AFC'
              }}
            />
            <UsersTable 
              customerUsers={props.customerUsers}
              onUserSearch={props.onUserSearch}
              usersTableRows={props.usersTableRows}
              usersModalData={props.usersModalData}
              setUsersModalData={props.setUsersModalData}
              roleOptions={props.roleOptions}
              removeCustomerUser={props.removeCustomerUser}
              saveCustomerUser={props.saveCustomerUser}
              updateCustomerUserData={props.updateCustomerUserData}
            />
          </>
        }
        <div className={classes.buttonsArea}>
          <Link to='/Tenants'>
            <SecondaryButton
              style={{width:'100px', height: 'auto'}}
              text='Cancel' 
            />
          </Link>
          <PrimaryButton
            className={classes.primaryButton}    
            style={{width:'100px', height: 'auto', marginLeft: '15px'}}
            text='SAVE'
            onClick={() => {
              if (formRef.current) formRef.current.handleSubmit()
            }}
            loading={props.isLoading}
          />
        </div>
      </div>
    </>
  )
}

export default CustomerDetails

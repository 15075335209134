import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import CustomerDetails from './CustomerDetails'
import { createCustomer, createCustomerUser, deactivateCustomerUser, getCustomer, getCustomerUsers, updateCustomer, updateCustomerUser } from '../../../store/customersReducer'
import { getAllowedUserRoles } from '../../../store/usersReducer'
import { setTenantLogo, getCustomer as getCustomerFromLocalStorage } from '../../../helpers/localStorage'
import { setLogo } from '../../../store/authReducer'

const CustomerDetailsContainer = () => {
  const dispatch = useDispatch()
  const [isDataSent, setIsDataSent] = useState(false)
  const [usersTableRows, setUsersTableRows] = useState([])
  const [usersModalData, setUsersModalData] = useState({isOpen: false})
  const [newCustomerId, setNewCustomerId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const userRoles = useSelector(state => state.usersReducer.allowedUserRoles)
  const customer = useSelector(state => state.customersReducer.customer)
  const customerUsers = useSelector(state => state.customersReducer.customerUsers)

  const {id} = useParams()

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(Number(id)))
      dispatch(getCustomerUsers(Number(id)))
    }
  }, [id, dispatch])

  useEffect(() => {
    dispatch(getAllowedUserRoles())
  }, [dispatch])

  useEffect(() => {
    !!customerUsers && setUsersTableRows(customerUsers)
  }, [customerUsers])

  const saveCustomer = (newCustomer) => {
    setIsLoading(true)
    dispatch(createCustomer(newCustomer))
      .then((resp) => {
        setIsLoading(false)
        if (resp) {
          setNewCustomerId(resp?.custId)
          setIsDataSent(true)
        }
      })
  }

  const saveCustomerUser = (newCustomer) => {
    const newUserData = _.pickBy(newCustomer, (v, k) => k !== 'userId')
    dispatch(createCustomerUser(customer.custId, newUserData))
      .then((resp) => {if (!!resp) setUsersModalData({isOpen: false})})
  }

  const updateCustomerData = (custId, updatedCustomer, customerName) => {
    setIsLoading(true)
    dispatch(updateCustomer(custId, updatedCustomer))
      .then((resp) => {
        if (!!resp) {
          if (getCustomerFromLocalStorage() === customerName) {
            setTenantLogo(resp.logo)
            dispatch(setLogo(resp.logo))
          }
        }
        return resp
      })
      .then((resp) => {
        setIsLoading(false)
        if (!!resp) {
          setIsDataSent(true)
        }
      })
  }

  const updateCustomerUserData = (updatedUser, userId) => {
    dispatch(updateCustomerUser(customer.custId,  {...updatedUser, userId}))
      .then((resp) => {if (!!resp) setUsersModalData({isOpen: false})})
  }

  const onUserSearch = (value) => {
    const searchResults = customerUsers.filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase()))
    if (!value) {
      setUsersTableRows(customerUsers)
    }
    setUsersTableRows(searchResults)
  }

  const removeCustomerUser = (userId, deleteStakeholder) => {
    dispatch(deactivateCustomerUser(customer.custId, userId, deleteStakeholder))
  }

  // const saveTenantUsersChanges = async() => {
  //   addedTenantUsers.forEach(user => saveCustomerUser(user))
  //   editedTenantUsers.forEach(user => updateCustomerUserData(user, user.userId))
  //   removedTenantUsers.forEach(user => removeCustomerUser(user))
  // }

  return (
    <CustomerDetails
      saveCustomer={saveCustomer}
      updateCustomer={updateCustomerData}
      dataForEditing={id ? customer : null}
      customerUsers={customerUsers}
      usersTableRows={usersTableRows}
      isDataSent={isDataSent}
      setIsDataSent={setIsDataSent}
      onUserSearch={onUserSearch}
      usersModalData={usersModalData}
      setUsersModalData={setUsersModalData}
      roleOptions={userRoles}
      newCustomerId={newCustomerId}
      isLoading={isLoading}
      removeCustomerUser={removeCustomerUser}
      saveCustomerUser={saveCustomerUser}
      updateCustomerUserData={updateCustomerUserData}
    />
  )
}

export default CustomerDetailsContainer

import { Redirect } from 'react-router-dom'
import {
  getToken, 
  getCurrentUserRoles, 
  getUserEmail,
  setTokenData, 
  setCurrentUserRoles, 
  setUserEmail,
  setUserName,
  setCustomer,
  setTenantLogo,
  setUserIdData,
  getUserName,
  getCustomer,
  getTenantLogo,
  getUserIdData,
  removeUserName,
  removeTokenData, 
  removeCurrentUserRoles,
  removeUserEmail,
  removeCustomer,
  removeTenantLogo,
  removeUserIdData,
  removeIsSynchronizedWithOffice,
  setIsSynchronizedWithOffice,
  removeIsSynchronizedWithGoogle,
  setIsSynchronizedWithGoogle,
  setUserAvatar,
  removeUserAvatar,
  getUserAvatar,
} from './../helpers/localStorage'
import { handleError } from './errorsReducer'
import { selectProjectTC } from './projectsReducer'
import axios from './../helpers/axiosHelper'
import { resetTableParams } from './tablesReducer'
import { setStakeholders } from './stakeholdersReducer'
import { setIsSynchronizedWithGoogleAC, setIsSynchronizedWithOfficeAC } from './synchronizationReducer'
import { getNewNotifications, setNewNotifications } from './notificationsReducer'
import moment from 'moment'

const initialState = {
  isSignedIn: getToken(),
  userRoles: getCurrentUserRoles(),
  email: getUserEmail(),
  userName: getUserName(),
  customerName: getCustomer(),
  logo: getTenantLogo(),
  userId: getUserIdData(),
  avatar: getUserAvatar(),
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_SIGNED_IN':
      return {...state, isSignedIn: action.isSignedIn} 
    case 'SET_USER_ROLES':
      return {...state, userRoles: action.userRoles} 
    case 'SET_EMAIL':
      return {...state, email: action.email} 
    case 'SET_USER_NAME':
      return {...state, userName: action.userName} 
    case 'SET_CUSTOMER':
      return {...state, customerName: action.customerName} 
    case 'SET_LOGO':
      return {...state, logo: action.logo} 
    case 'SET_USER_ID':
      return {...state, userId: action.userId} 
    case 'SET_USER_AVATAR':
      return {...state, avatar: action.avatar} 
    default: return state
  } 
}

export const setIsSignedIn = (isSignedIn) => ({type: 'SET_IS_SIGNED_IN', isSignedIn})
export const setUserRoles = (userRoles) => ({type: 'SET_USER_ROLES', userRoles})
export const setEmail = (email) => ({type: 'SET_EMAIL', email})
export const setName = (userName) => ({type: 'SET_USER_NAME', userName})
export const setCustomerName = (customerName) => ({type: 'SET_CUSTOMER', customerName})
export const setLogo = (logo) => ({type: 'SET_LOGO', logo})
export const setUserId = (userId) => ({type: 'SET_USER_ID', userId})
export const setAvatar = (avatar) => ({type: 'SET_USER_AVATAR', avatar})

export const logIn = (data) => (dispatch) => {
  dispatch(getNewNotifications(false))
  dispatch(setIsSignedIn(data.token))
  dispatch(setUserRoles(data.roles))
  dispatch(setEmail(data.email))
  dispatch(setName(`${data.firstName} ${data.lastName}`))
  dispatch(setCustomerName(data.customerName))
  dispatch(setLogo(data.logo))
  dispatch(setUserId(data.userId))
  dispatch(setIsSynchronizedWithOfficeAC(data.syncMsOffice))
  dispatch(setIsSynchronizedWithGoogleAC(data.syncGoogle))
  dispatch(setAvatar(data.photoUrl))
  setTokenData(data.token, data.tokenExpiryDate)
  setCurrentUserRoles(data.roles)
  setUserEmail(data.email)
  setUserName(`${data.firstName} ${data.lastName}`)
  setCustomer(data.customerName)
  setTenantLogo(data.logo)
  setUserIdData(data.userId)
  setIsSynchronizedWithOffice(data.syncMsOffice || false)
  setIsSynchronizedWithGoogle(data.syncGoogle || false)
  setUserAvatar(data.photoUrl)
}

export const logOut = () => (dispatch) => {
  dispatch(setIsSignedIn(false))
  dispatch(setUserRoles(null))
  dispatch(setEmail(null))
  dispatch(setName(null))
  dispatch(setCustomerName(null))
  dispatch(selectProjectTC(0, []))
  dispatch(setLogo(''))
  dispatch(setUserId(0))
  dispatch(resetTableParams('all'))
  dispatch(setStakeholders([]))
  dispatch(setIsSynchronizedWithOfficeAC(false))
  dispatch(setIsSynchronizedWithGoogleAC(false))
  dispatch(setNewNotifications([]))
  dispatch(setAvatar(null))
  removeTokenData()
  removeCurrentUserRoles()
  removeUserEmail()
  removeUserName()
  removeCustomer()
  removeTenantLogo()
  removeUserIdData()
  removeUserAvatar()
  removeIsSynchronizedWithOffice()
  removeIsSynchronizedWithGoogle()
  return <Redirect to='/login' />
}

export const updatePersonalData = (data) => (dispatch) => {
  return axios.put('/users', data)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setEmail(response.data.user.email))
        dispatch(setName(`${response.data.user.firstName} ${response.data.user.lastName}`))
        dispatch(setAvatar(response.data.user.photoUrl ? `${response.data.user.photoUrl}?${moment()}` : null))
        setUserEmail(response.data.user.email)
        setUserName(`${response.data.user.firstName} ${response.data.user.lastName}`)
        setUserAvatar(response.data.user.photoUrl ? response.data.user.photoUrl : null)
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const sendResetPasswordEmail = (email) => (dispatch) => {
  return axios.post(`/password?email=${email}`)
    .then(response => {
      if (response.status === 200 ) {
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const createNewPassword = (password, token) => (dispatch) => {
  return axios.post(`/reset-password`, {password, token})
    .then(response => {
      if (response.status === 200 ) {
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export default authReducer

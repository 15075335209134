import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'

const initialState = {
  userList: [],
  allowedUserRoles: []
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_LIST':
      return {...state, userList: action.users} 
    case 'REMOVE_USER':
      return {...state, userList: state.userList.filter(user => user.userId !== action.userId)}
    case 'EDIT_USER':
      return {...state, userList: state.userList.map(user => {
        if (user.userId === action.userId) {
          return {...user, ...action.userData}
        } else {
          return user
        }
      })}
    case 'DEACTIVATE_USER':
      return {...state, userList: state.userList.map(user => {
        if (user.userId === action.userId) {
          return {...user, isActive: false}
        } else {
          return user
        }
      })}  
    case 'ADD_USER':
      return {...state, userList: [...state.userList, action.userData]} 
    case 'SET_ALLOWED_USER_ROLES':
      return {...state, allowedUserRoles: action.roles}
    default: return state
  } 
}

export const setUserList = (users) => ({type: 'SET_USER_LIST', users})
export const removeUser = (userId) => ({type: 'REMOVE_USER', userId})
export const editUser = (userData, userId) => ({type: 'EDIT_USER', userData, userId})
export const deactivateUserById = (userId) => ({type: 'DEACTIVATE_USER', userId})
export const addUser = (userData) => ({type: 'ADD_USER', userData})
export const setAllowedUserRoles = (roles) => ({type: 'SET_ALLOWED_USER_ROLES', roles})

export const getUserList = (isActive = false) => (dispatch) => {
  return axios.get(`/users${isActive ? '?is_active=true' : ''}`)
    .then(response => {
      response.status === 200 
        ? dispatch(setUserList(response.data.users))
        : dispatch(handleError(response.data))
    })
    .catch(error => dispatch(handleError(error)))
}

export const deleteUser = (userId) => (dispatch) => {
  return axios.delete(`/users/${userId}`)
    .then(response => {
      response.status === 200 
        ? dispatch(removeUser(userId))
        : dispatch(handleError(response.data))
    })
    .catch(error => dispatch(handleError(error)))
}

export const deactivateUser = (userId, delete_stakeholder = false) => (dispatch) => {
  return axios.delete(`/users/${userId}/deactivate?delete_stakeholder=${delete_stakeholder}`)
    .then(response => {
      response.status === 200 
        ? dispatch(deactivateUserById(userId))
        : dispatch(handleError(response.data))
    })
    .catch(error => dispatch(handleError(error)))
}

export const editUserData = (userData, userId) => (dispatch) => {
  const formData = new FormData()
  formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
  formData.append('user', new Blob([JSON.stringify({...userData}, null, 2)], {type: 'application/json'}))
  return axios.put(`/users/${userId}`, formData)
    .then(response => {
      if (response.status === 200) {
        dispatch(editUser(userData, userId))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const createUser = (userData) => (dispatch) => {
  const formData = new FormData()
  formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
  formData.append('user', new Blob([JSON.stringify({...userData}, null, 2)], {type: 'application/json'}))
  return axios.post(`/users`, formData)
    .then(response => {
      if (response.status === 201) {
        dispatch(addUser(response.data.user))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const getAllowedUserRoles = () => (dispatch) => {
  return axios.get('/roles/users')
    .then(response => {
      response.status === 200 
        ? dispatch(setAllowedUserRoles(response.data.roles))
        : dispatch(handleError(response.data))
    })
    .catch(error => dispatch(handleError(error)))
}

export default usersReducer

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, editUserData, getAllowedUserRoles } from '../../store/usersReducer'
import UserModal from '../common/UsersTable/UserModal'

const UsersModal = (props) => {
  const dispatch = useDispatch()
  const userRoles = useSelector(state => state.usersReducer.allowedUserRoles)

  useEffect(() => {
    dispatch(getAllowedUserRoles())
  }, [dispatch])

  const closeModal = () => {
    props.setModalData({isOpen: false})
  }

  const editUser = (userData, userId) => {
    dispatch(editUserData(userData, userId))
      .then((resp) => !!resp && closeModal())
  }

  const addUser = (userData) => {
    dispatch(createUser(userData))
      .then((resp) => !!resp && closeModal())
  }

  return (
    <UserModal
      addUser={addUser}
      editUser={editUser}
      modalData={props.modalData}
      setUsersModalData={props.setModalData}
      roleOptions={userRoles}
    />
  )
}

export default UsersModal

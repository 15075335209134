import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { Popconfirm } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import classes from './Customers.module.css'
import {tableWidth} from './../common/sizes'
import {ReactComponent as EditIcon} from './../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../img/icons/deleteIcon.svg'
import { setTableColumns } from '../../store/tablesReducer'
import {ReactComponent as ExclamationCircle} from './../../img/icons/exclamationCircle.svg'

const bigColumnWidth = tableWidth * 0.4
const smallColumnWidth = tableWidth * 0.2

const getColumns = (deleteCustomer, selectedIsActive) => ([
  { field: 'name',
    headerName: 'Tenant',
    width: bigColumnWidth,
  },
  { field: 'actions', 
    headerName: 'Actions', 
    sortable: false, 
    width: smallColumnWidth,
    renderCell: (params) => {
      return (
        <div style={{display:'flex', alignItems:'center'}} >
          <Link to={`/Tenants/edit/${params.row.custId}`} style={{lineHeight:1}} >
            <EditIcon 
              className={classes.tableIcon} 
              style={{marginRight: 
                selectedIsActive === 'INACTIVE' 
                  ? '0px' 
                  : moment().isBefore(params.row.activeTill) ? '15px' : '40px'
              }}
            />
          </Link>
          {moment().isBefore(params.row.activeTill) && 
            <Popconfirm
              title="Are you sure to delete this tenant?"
              onConfirm={() => deleteCustomer(params.row.custId)}
              okText="Yes"
              cancelText="No"
              icon={<ExclamationCircle />}
              cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
              okButtonProps={{type: 'default'}}
            >
              <DeleteIcon className={classes.tableIcon}/>
            </Popconfirm>
          }
        </div>
      )
    }
  },
  { field: 'activeFrom',
    headerName: 'Active from',
    width: smallColumnWidth,
    type: 'date',
    renderCell: (params) => {
      return (
        <span>
          {moment(params.row.activeFrom).format('DD.MM.YYYY')}
        </span>
      )
    }
  },
  { field: 'activeTill',
    headerName: 'Active till',
    width: smallColumnWidth,
    type: 'date', 
    renderCell: (params) => {
      return (
        <span>
          {moment(params.row.activeTill).format('DD.MM.YYYY')}
        </span>
      )
    }
  },
])

const CustomersTable = (props) => {
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [props?.customers])
  
  const columns = useMemo(() => {
    return getColumns(props.deleteCustomer, props.selectedIsActive)
    // eslint-disable-next-line
  }, [props.selectedIsActive])

  const tableAppearance = useSelector(state => state.tablesReducer?.customersTable)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    apiRef?.current?.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef?.current?.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('customersTable', colsData))
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        getRowId={e => e?.custId}
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : columns}  
        rows={props.customers || []}   
        pageSize={10} 
        rowsPerPageOptions={[10]}
        components={{
          Toolbar: CustomToolbar,
        }}
        style={{borderRadius:'15px', border:'none'}}
        rowCount={props.customers?.length || 0}
        pagination
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default CustomersTable

import React from 'react'
import classes from './Login.module.css'
import LoginForm from './LoginForm'
import {ReactComponent as CompanyLogo} from './../../img/companyLogo.svg'
import AccessModal from './AccessModal/AccessModal'
import { Link } from 'react-router-dom'

const Login = (props) => {
  return (
      <div className={classes.wrapper}>
        <div className={classes.formArea}>
          <CompanyLogo className={classes.companyLogo}/>
          <div className={classes.formTitle}> 
            SIGN IN TO ACCOUNT
          </div>
          <LoginForm 
            loginError={props.loginError}
            signIn={props.signIn}
          />
          <div style={{marginTop: '10px'}}>
            <span>
              Don’t have an account yet?
            </span>
            <span
              onClick={() => props.setIsAccessModalOpen(true)}
              style={{color:'#0E9AFC', marginLeft:'6px', cursor: 'pointer', fontWeight: 600}}
            >
              Request Access
            </span>
          </div>
          <Link to='/ForgotPassword' style={{color:'#0E9AFC', marginTop: '10px', cursor: 'pointer', fontWeight: 600}}>
            Forgot your password?
          </Link>
          {props.isAccessModalOpen && 
            <AccessModal
              setIsAccessModalOpen={props.setIsAccessModalOpen}
              isAccessModalOpen={props.isAccessModalOpen}
            />
          }
        </div>
      </div>
  )
}

export default Login

import { handleError } from './errorsReducer'
import axios from './../helpers/axiosHelper'

const initialState = {
  templates: [],
  selectedTemplate: null,
}

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATES':
      return {...state, templates: action.templates} 
    case 'UPDATE_TEMPLATE':
      return {...state, templates: state.templates.map(t => t.code === action.template.code ? action.template : t)} 
    case 'SET_SELECTED_TEMPLATE':
      return {...state, selectedTemplate: action.template} 
      default: return state
  } 
}

export const setTemplates = (templates) => ({type: 'SET_TEMPLATES', templates})
export const updateTemplate = (template) => ({type: 'UPDATE_TEMPLATE', template})
export const setSelectedTemplate = (template) => ({type: 'SET_SELECTED_TEMPLATE', template})

export const getTemplatesTC = () => (dispatch) => {
  return axios.get(`/email-samples`)
    .then(response => {
      if (response.status === 200) {
        dispatch(setTemplates(response.data.emailSampleList))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export const updateTemplateTC = (template) => (dispatch) => {
  return axios.put(`/email-samples`, template)
    .then(response => {
      if (response.status === 200) {
        dispatch(updateTemplate(template))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export const getTemplateByCodeTC = (code) => (dispatch) => {
  return axios.get(`/email-samples/${code}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(setSelectedTemplate(response.data))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export default emailTemplatesReducer

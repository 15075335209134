import React from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {withStyles} from '@material-ui/core'
import { DatePicker as AntdDatePicker} from 'antd'

const StyledDatePicker = withStyles({
  root: {
    width: '325px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black !important'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #0E9AFC !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black !important'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: 'black',
      fontWeight: 600,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiPaper-root': {
      border: '2px solid #0E9AFC !important'
    }
  },

})(KeyboardDatePicker)

const DatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        disableToolbar
        variant='inline'
        inputVariant='outlined'
        format='dd.MM.yyyy'
        id={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        style={props.style}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export const DatePickerForTable = (props) => {
  return (
    <AntdDatePicker
      className={`forTable ${props.hasError ? 'error' : ''}`}
      showToday={props.showToday}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      allowClear={props.allowClear}
      disabledDate={props.disabledDate}
    />
  )
}

export default DatePicker

import { createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import authReducer from './authReducer'
import stakeholderMapReducer from './stakeholderMapReducer'
import canvasReducer from './canvasReducer'
import stakeholderFormReducer from './stakeholderFormReducer'
import projectsReducer from './projectsReducer'
import organizationsReducer from './organizationsReducer'
import stakeholdersReducer from './stakeholdersReducer'
import errorsReducer from './errorsReducer'
import chartsReducer from './chartsReducer'
import usersReducer from './usersReducer'
import customersReducer from './customersReducer'
import tablesReducer from './tablesReducer'
import engagementReducer from './engagementReducer'
import notificationsReducer from './notificationsReducer'
import synchronizationReducer from './synchronizationReducer'
import emailTemplatesReducer from './emailTemplatesReducer'

const reducers = combineReducers({
  authReducer,
  stakeholderMapReducer,
  canvasReducer,
  stakeholderFormReducer,
  projectsReducer,
  organizationsReducer,
  stakeholdersReducer,
  errorsReducer,
  chartsReducer,
  usersReducer,
  customersReducer,
  tablesReducer,
  engagementReducer,
  notificationsReducer,
  synchronizationReducer,
  emailTemplatesReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)))

export default store

import React, {useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Customers from './Customers'
import {deleteCustomer, getCustomers, setCustomer, setCustomerUsers} from './../../store/customersReducer'
import moment from 'moment'

const CustomersContainer = () => { 
  const dispatch = useDispatch() 
  const [searchResults, setSearchResults] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedIsActive, setSelectedIsActive] = useState('ACTIVE')

  const customers = useSelector(store => store.customersReducer.customers)

  useEffect(() => {
    dispatch(getCustomers(selectedIsActive))
    dispatch(setCustomer({}))
    dispatch(setCustomerUsers([]))
  }, [selectedIsActive, dispatch])

  useEffect(() => {
    const hasInactiveItem = selectedIsActive === 'ACTIVE' && customers.some(cust => moment().isAfter(moment(cust.activeTill)))
    const hasActiveItem = !selectedIsActive === 'INACTIVE' && customers.some(cust => moment().isBefore(moment(cust.activeTill)))
    if (hasInactiveItem || hasActiveItem) dispatch(getCustomers(selectedIsActive))
  }, [customers, dispatch, selectedIsActive])
  
  const onSearch = useCallback((searchQuery) => {
    if (!searchQuery?.length) {
      setSearchResults(null)
    } else {
      const results = customers.filter(item => item.name?.toLowerCase().includes(searchQuery?.toLowerCase()))
      setSearchResults(results)
    }
  }, [customers])

  useEffect(() => {
    onSearch(searchQuery)
  }, [searchQuery, customers, onSearch])

  const removeCustomer = (custId) => {
    dispatch(deleteCustomer(custId))
  }

  const periodOptions = [
    {id: 1, value: 'ALL', name: 'All'},
    {id: 2, value: 'ACTIVE', name: 'Active'},
    {id: 3, value: 'INACTIVE', name: 'Inactive'},
  ]

  return (
    <Customers 
      customers={searchResults ? searchResults : customers}
      deleteCustomer={removeCustomer}
      selectedIsActive={selectedIsActive}
      setSelectedIsActive={setSelectedIsActive}
      periodOptions={periodOptions}
      setSearchQuery={setSearchQuery}
    />
  )
}

export default CustomersContainer

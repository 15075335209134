import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from './../../helpers/axiosHelper'
import { handleError } from '../../store/errorsReducer'
import NavBar from './NavBar'
import { useDispatch, useSelector } from 'react-redux'

const NavBarContainer = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [activeItem, setActiveItem] = useState(location.pathname)
  const [infoAlert, setInfoAlert] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum ipsum commodo sapien biLorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum ipsum commodo sapien bi')
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)

  const userEmail = useSelector(store => store.authReducer.email)
  const userName = useSelector(store => store.authReducer.userName)
  const currentUserRoles = useSelector(store => store.authReducer.userRoles)
  const logo = useSelector(store => store.authReducer.logo)
  const isAdmin = currentUserRoles == null ? false : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin" || role.code.toLowerCase() === "customer admin")
  const isSystemAdmin = currentUserRoles == null ? false : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin")

  const sendFeedbackMessage = (message) => {
    return axios.post(`/email/sending-feedback`, {message, email: userEmail, name: userName})
      .then(response => {
        response.status === 200 ? setIsFeedbackSent(true) : dispatch(handleError(response.data))
      })
      .catch(error => dispatch(handleError(error)))
  }

  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false)
    setIsFeedbackSent(false)
  }

  return (
    <NavBar 
      activeItem={activeItem}
      closeFeedbackModal={closeFeedbackModal}
      infoAlert={infoAlert}
      isFeedbackSent={isFeedbackSent}
      isAdmin={isAdmin}
      isSystemAdmin={isSystemAdmin}
      isFeedbackModalOpen={isFeedbackModalOpen}
      setIsFeedbackModalOpen={setIsFeedbackModalOpen}
      setActiveItem={setActiveItem}
      setInfoAlert={setInfoAlert}
      sendFeedbackMessage={sendFeedbackMessage}
      logo={logo}
      className={props.className}
      setIsToolsVisible={props.setIsToolsVisible}
    />
  )
}

export default NavBarContainer

import { Popconfirm } from 'antd'
import classes from './UsersTable.module.css'
import {tableWidth} from '../sizes'
import {ReactComponent as EditIcon} from './../../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/deleteIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../img/icons/exclamationCircle.svg'
import { useState } from 'react'

const partOfTableWidthForBigColumn = 0.28
const partOfTableWidthForSmallColumn = 0.22
const partOfTableWidthForAdditionalColumn = 0.13
const nameColumnWidth = tableWidth * partOfTableWidthForBigColumn
const columnWidth = tableWidth * partOfTableWidthForSmallColumn
const actionColumnWidth = tableWidth * partOfTableWidthForAdditionalColumn

const getColumns = (setModalData, removeUser, users) => [
  { field: 'name',
    headerName: 'Name',
    width: nameColumnWidth,
    sortComparator: (v1, v2, user1, user2) => {
      const user1Data = users?.find(user => user.userId === user1.id)
      const user2Data = users?.find(user => user.userId === user2.id)
      return `${user1Data?.firstName} ${user1Data?.lastName}`?.localeCompare(`${user2Data?.firstName} ${user2Data?.lastName}`)
    },
    renderCell: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`
    }
  },
  { field: 'actions', 
    headerName: 'Actions', 
    sortable: false, 
    width: actionColumnWidth,
    renderCell: (params) => {
      return (
        <div style={{display:'flex', alignItems:'center'}} >
          <EditIcon 
            className={classes.tableIcon}
            onClick={() => setModalData({isOpen: true, userData: params.row})}
            style={{marginRight: '15px'}}
          />
          {params.row.isActive && 
            <ConfirmDelete
              removeUser={removeUser}
              userId={params.row.userId}
            />
          }
        </div>
      )
    }
  },
  { field: 'email', headerName: 'Email', width: columnWidth},
  { field: 'phoneNumber', headerName: 'Phone', width: columnWidth},
  { field: 'isActive', 
    headerName: 'Status', 
    width: actionColumnWidth, 
    renderCell: (params) => {
      const color = params.row.isActive ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'
      return (
        <div className={classes.tableIsActiveColumn} style={{backgroundColor: color}}>
          {params.row.isActive ? 'Active' : 'Inactive'}
        </div>
      )
    }
  },
]

const ConfirmDelete = ({removeUser, userId}) => {
  const [confirmMessageNumber, setConfirmMessageNumber] = useState(0)
  return (
    <>
      {confirmMessageNumber === 0 &&
        <DeleteIcon className={classes.tableIcon} onClick={() => setConfirmMessageNumber(1)}/>
      }
      {confirmMessageNumber === 1 &&
        <Popconfirm
          title={"Are you sure to delete this user?"}
          onConfirm={() => setConfirmMessageNumber(2)}
          onCancel={() => setConfirmMessageNumber(0)}
          okText="Yes"
          cancelText="No"
          icon={<ExclamationCircle />}
          cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
          okButtonProps={{type: 'default'}}
          visible={confirmMessageNumber === 1}
        >
          <DeleteIcon className={classes.tableIcon}/>
        </Popconfirm>
      }
      {confirmMessageNumber === 2 &&
        <Popconfirm
          title="Should the assigned contact be deleted?"
          onConfirm={() => {removeUser(userId, true); setConfirmMessageNumber(0)}}
          onCancel={() => {removeUser(userId, false); setConfirmMessageNumber(0)}}
          okText="Yes"
          cancelText="No"
          icon={<ExclamationCircle />}
          cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
          okButtonProps={{type: 'default'}}
          visible={confirmMessageNumber === 2}
        >
          <DeleteIcon className={classes.tableIcon}/>
        </Popconfirm>
      }
    </>
  )
}

export default getColumns
